import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  // // production keys
  apiKey: "AIzaSyChSFzHUZfPRIxUA1A9elriC0rFNF4-jkY",
  authDomain: "yt-app-3b11e.firebaseapp.com",
  projectId: "yt-app-3b11e",
  storageBucket: "yt-app-3b11e.appspot.com",
  messagingSenderId: "970195026365",
  appId: "1:970195026365:web:eb308ee7090adffbf392d4",
  measurementId: "G-50YLGW55J4"

  // apiKey: "AIzaSyAvti0f3JN-l7fg7Okd5zCLZ1ifKEyZtN0",
  // authDomain: "tik-tok-clone-8b9f8.firebaseapp.com",
  // databaseURL: "https://tik-tok-clone-8b9f8-default-rtdb.firebaseio.com",
  // projectId: "tik-tok-clone-8b9f8",
  // storageBucket: "tik-tok-clone-8b9f8.appspot.com",
  // messagingSenderId: "134027950405",
  // appId: "1:134027950405:web:371ad13ec855f8f6e1662c",
  // measurementId: "G-EKW7CZT777"


  // apiKey: "AIzaSyAnf0NYC_AY84VzTL5C6c51L2RV00VYoSI",
  // authDomain: "fir-a54c2.firebaseapp.com",
  // projectId: "fir-a54c2",
  // storageBucket: "fir-a54c2.appspot.com",
  // messagingSenderId: "6882406664",
  // appId: "1:6882406664:web:0c640744012969e487bcbd",
  // measurementId: "G-MNRPJ98XDZ"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

