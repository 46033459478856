import { createSlice } from "@reduxjs/toolkit";
import { convertTimeToSecs } from "helpers/convert-time-to-secs";
import { toast } from "react-toastify";
// get videos async thunk
// export const getVideosAsyncThunk = createAsyncThunk(
//   "youtube/getVideosAsyncThunk",
//   catchAsync(async ({ perPage, search, first }, { getState }) => {
//     const state = getState();
//     var q;
//     if (state.youtube.videos.data.length && !first) {
//       q = query(
//         collection(db, "episodes"),
//         ...(search
//           ? [
//               orderBy("youtubeTitle"),
//               orderBy("timestamp"),
//               startAt(search.toLowerCase()),
//               endAt(search.toLowerCase() + "\uf8ff"),
//             ]
//           : [orderBy("timestamp", "desc")]),
//         startAfter(state.youtube.videos.lastItem),
//         limit(perPage ?? 10)
//       );
//     } else {
//       q = query(
//         collection(db, "episodes"),
//         ...(search
//           ? [
//               orderBy("youtubeTitle"),
//               orderBy("timestamp"),
//               startAt(search.toLowerCase()),
//               endAt(search.toLowerCase() + "\uf8ff"),
//             ]
//           : [orderBy("timestamp", "desc")]),
//         limit(perPage ?? 10)
//       );
//     }
//     // var q = query(collection(db, "episodes"), limit(perPage ?? 10));
//     const querySnapshot = await getDocs(q);
//     const data = querySnapshot.docs.map((el) => ({ ...el.data(), id: el.id }));
//     const all = {
//       loadMore: data.length === +perPage,
//       data,
//       first: !!first,
//       lastItem:
//         querySnapshot.size > 0
//           ? querySnapshot.docs[querySnapshot.docs.length - 1]
//           : state.youtube.videos.lastItem,
//     };
//     return all;
//   })
// );
// export const getVideosForTagAsyncThunk = createAsyncThunk(
//   "youtube/getVideosForTagAsyncThunk",
//   catchAsync(async ({ perPage, search, first, path }, { getState }) => {
//     console.log({
//       perPage,
//       search,
//       first,
//       path,
//     });
//     const state = getState();
//     var q;
//     if (state.youtube.pathVideos.data.length && !first) {
//       q = query(
//         collection(db, "episodes"),
//         ...(path ? [where("path", "==", path)] : []),
//         ...(search
//           ? [
//               orderBy("timestamp"),
//               startAt(search.toLowerCase()),
//               endAt(search.toLowerCase() + "\uf8ff"),
//             ]
//           : [orderBy("timestamp", "desc")]),
//         startAfter(state.youtube.pathVideos.lastItem),
//         limit(perPage ?? 10)
//       );
//     } else {
//       q = query(
//         collection(db, "episodes"),
//         ...(path ? [where("path", "==", path)] : []),
//         ...(search
//           ? [
//               orderBy("timestamp"),
//               startAt(search.toLowerCase()),
//               endAt(search.toLowerCase() + "\uf8ff"),
//             ]
//           : [orderBy("timestamp", "desc")]),
//         limit(perPage ?? 10)
//       );
//     }
//     // var q = query(collection(db, "episodes"), limit(perPage ?? 10));
//     const querySnapshot = await getDocs(q);
//     const data = querySnapshot.docs.map((el) => ({ ...el.data(), id: el.id }));
//     const all = {
//       loadMore: data.length === +perPage,
//       data,
//       first: !!first,
//       lastItem:
//         querySnapshot.size > 0
//           ? querySnapshot.docs[querySnapshot.docs.length - 1]
//           : state.youtube.pathVideos.lastItem,
//     };
//     console.log("all", all);
//     return all;
//   })
// );
// export const getVideoAsyncThunk = createAsyncThunk(
//   "youtube/getVideoAsyncThunk",
//   catchAsync(async ({ id, patch, callBack }, { getState }) => {
//     const documentRef = doc(db, "episodes", id);
//     const documentSnapshot = await getDoc(documentRef);

//     if (documentSnapshot.exists()) {
//       const data = documentSnapshot.data();
//       // Process the data or return it as needed
//       return { data, patch };
//     } else {
//       // Document doesn't exist
//       console.log("Document not found!");
//       throw "Quote does't exist!";
//     }
//   })
// );
/**
 * @type {("input" | "answer" | "result" | "finish" | "skipped")[]}
 */
export const END_STATES = ["answer", "result", "finish", "skipped"];

/**
 * @typedef Subtitle
 * @property {number} index
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} text
 * @property {number} round
 * @property {boolean} isCompleted
 * @property {boolean} helpUsed
 * @property {boolean} replayed
 * @property {boolean} skipped
 * @property {number} helpCount
 * @property {number} replayCount
 * @property {number} skippedCount
 */

const initialState = {
  // subtitle: [],
  // thumbnail: null,
  // progress: {},
  // url: "",
  // authorName: "",
  // videoName: "",
  // isPlaying: false,
  // isStarted: false,
  // preventStop: false,
  // video: {},
  // videos: {
  //   current_page: 1,
  //   loadMore: true,
  //   data: [],
  // },
  // pathVideos: {
  //   current_page: 1,
  //   loadMore: true,
  //   data: [],
  // },
  // fullScreenMode: false,
  // savedId: "",
  // currentIndex: 0,
  // finish: false,
  // manager states
  // errors: {},
  // loadings: {},
  // errorMessages: {},
  // errorCodes: {},
  // paramsForThunk: {},
  // round: 1,
  // screen: "input",
  // activeRoundSubtitles: [],
  // finishIndex: 0,
  quoteData: {
    startTime: 0,
    endTime: 0,
    tags: [],
    level: 0,
    sTags: [],
    quote: "",
    videoDuration: 0,
  },
  /**
  //  * @type {"player" | "editor"}
   */
  // state: "player",
  /**
  //  * @type {"input" | "level" | "tags"}
   */
  editState: "input",
};

/**
 * @param {typeof initialState} state
 * @returns
 */
// const getCurrentIndex = (state) =>
//   getActiveRoundSubtitles(state).findIndex(
//     (item) => item.index === state.currentIndex
//   );

/**
 *
 * @param {Partial<typeof initialState>} state
 */
// const getActiveRoundSubtitles = (state) =>
//   state.subtitle.filter((item) => item.round === state.round);

/**
 * @param {typeof initialState} state
 */
// export const getFilteredRoundSubtitles = (state) =>
//   getActiveRoundSubtitles(state).filter(
//     (el) => el.isCompleted && (el.helpUsed || el.skipped || el.replayed)
//   );

/**
 * @param {typeof initialState} state
 *
 */
// const getNextSubtitleItem = (state) => {
//   const subtitlesOfCurrentRound = getActiveRoundSubtitles(state);
//   const index = getCurrentIndex(state);
//   return subtitlesOfCurrentRound[index + 1];
// };

/**
 *
 * @param {typeof initialState} state
 */
// const getCurrentSubtitle = (state) => {
//   const { currentIndex } = state;
//   const subtitlesOfCurrentRound = getActiveRoundSubtitles(state);
//   return subtitlesOfCurrentRound.find((item) => item.index === currentIndex);
// };

/**
 *
 * @param {typeof initialState} state
 * @returns {number}
 */
// const getLastRoundSubtitlesIndex = (state) => {
//   const subtitlesOfCurrentRound = getActiveRoundSubtitles(state);
//   return subtitlesOfCurrentRound.at(-1)?.index;
// };

/**
 *
 * @param {typeof initialState} state
 * @returns
 */
// const getPreviousIndex = (state) => {
//   const subtitlesOfCurrentRound = getActiveRoundSubtitles(state);
//   const index = getCurrentIndex(state);
//   if (subtitlesOfCurrentRound[index - 1]) {
//     return subtitlesOfCurrentRound[index - 1]?.index;
//   } else {
//     return null;
//   }
// };
/**
 *
 * @param {typeof initialState} state
 * @returns {Partial<typeof initialState>}
 */
// const setRoundCompletedFunction = (state) => {
//   const done = getFilteredRoundSubtitles(state);
//   if (done.length !== 0) {
//     return {
//       subtitle: state.subtitle.map((el) => {
//         if (el.helpUsed || el.skipped || el.replayed) {
//           return {
//             ...el,
//             round: el.round ? el.round + 1 : 1,
//             isCompleted: false,
//             helpUsed: false,
//             replayed: false,
//             helpCount: 0,
//             replayCount: 0,
//             skipped: false,
//           };
//         }
//         return el;
//       }),
//       currentIndex: done.at(0).index,
//       round: state.round + 1,
//       screen: "input",
//     };
//   }
//   return null;
// };

const youtubeSlice = createSlice({
  name: "youtube",
  initialState,
  reducers: {
    // setSubtitle(state, action) {
    //   state.subtitle = action.payload?.subtitle;
    //   state.url = action.payload?.url;
    //   state.thumbnail = action.payload?.thumbnail;
    //   state.videoName = action.payload?.videoName;
    //   state.authorName = action.payload?.authorName;
    //   if (action.payload?.currentIndex) {
    //     state.currentIndex = action.payload?.currentIndex ?? 0;
    //   }
    //   state.round = action.payload?.round ?? 1;
    // },
    // previousSubtitle(state, action) {
    //   const prevIndex = getPreviousIndex(state);
    //   if (typeof prevIndex === "number") {
    //     state.currentIndex = prevIndex;
    //     state.screen = state.subtitle[prevIndex].skipped ? "skipped" : "answer";
    //   } else {
    //     toast.error("You are at the first subtitle");
    //   }
    // },
    // setScreenState(state, action) {
    //   state.screen = action.payload;
    // },
    // changePlay(state, action) {
    //   state.isPlaying = action.payload;
    //   if (state.subtitle[state.currentIndex]) {
    //     if (action.payload) {
    //       if (state.subtitle[state.currentIndex].replayCount >= 1) {
    //         state.subtitle[state.currentIndex].replayed = true;
    //       }
    //       state.subtitle[state.currentIndex].replayCount += 1;
    //     }
    //   }
    // },
    // switchFullScreen(state, action) {
    //   state.fullScreenMode = !state.fullScreenMode;
    // },
    // quitLearn(state, action) {
    //   state.currentIndex = 0;
    //   state.isStarted = action.payload ?? false;
    //   state.round = 1;
    //   state.screen = "input";
    //   state.subtitle = state.subtitle.map((el) => {
    //     return {
    //       ...el,
    //       isCompleted: false,
    //       helpUsed: false,
    //       replayed: false,
    //       helpCount: 0,
    //       replayCount: 0,
    //       skipped: false,
    //     };
    //   });
    //   localStorage.removeItem(state.url);
    // },
    // toggleStartLearn(state, action) {
    //   if (action.payload?.state === "stop") {
    //     state.isStarted = false;
    //   } else {
    //     state.isStarted = true;
    //   }
    //   state.preventStop = action.payload?.preventStop;
    //   if (action.payload?.currentIndex) {
    //     state.currentIndex = action.payload.currentIndex;
    //   }
    // },
    // completeSubtitle(state, action) {
    //   state.subtitle[state.currentIndex].isCompleted = true;
    //   if (state.currentIndex < getLastRoundSubtitlesIndex(state)) {
    //     state.screen = "answer";
    //   } else {
    //     const sentencesCompletedWithHelpUsed = getFilteredRoundSubtitles(state);
    //     if (sentencesCompletedWithHelpUsed.length === 0) {
    //       state.screen = "finish";
    //     } else {
    //       state.screen = "result";
    //     }
    //   }
    // },
    // setHelpUsed(state, action) {
    //   const active = getCurrentSubtitle(state);
    //   state.subtitle[state.currentIndex].helpUsed = true;
    //   const helpCount = active.helpCount - 1;
    //   if (
    //     helpCount === Math.max(...active.text.split(" ").map((el) => el.length))
    //   ) {
    //     toast.error("Word Completed!!!");
    //   } else {
    //     state.subtitle[state.currentIndex].helpCount = active.helpCount + 1;
    //   }
    // },
    // setReplayed(state, action) {
    //   if (state.subtitle[state.currentIndex]) {
    //     state.subtitle[state.currentIndex].replayed = true;
    //     state.subtitle[state.currentIndex].replayCount += 1;
    //   }
    // },
    // setRoundCompleted(state, action) {
    //   const modified = setRoundCompletedFunction(state);
    //   if (modified) {
    //     state.subtitle = modified.subtitle;
    //     state.currentIndex = modified.currentIndex;
    //     state.round = modified.round;
    //     state.screen = modified.screen;
    //   }
    // },
    // setSkipped(state, action) {
    //   // This is the function that is called when a user clicks the "Next" button
    //   // It checks to see if the user has completed the last round, and if so, it changes
    //   // the state to display the "Results" screen.
    //   // If the user hasn't completed the last round, then it changes the state to
    //   // display the "Round" screen.
    //   if (state.screen === "finish") {
    //     if (state.finishIndex === 0) {
    //       toast.success("You have completed the Video, Press again to restart");
    //       state.finishIndex = 1;
    //       return;
    //     }
    //     state.finishIndex = 0;
    //     state.screen = "input";
    //     state.currentIndex = 0;
    //     state.round = 1;
    //     state.subtitle = state.subtitle.map((el) => ({
    //       ...el,
    //       isCompleted: false,
    //       skipped: false,
    //       helpUsed: false,
    //       replayCount: 0,
    //       helpCount: 0,
    //       round: 1,
    //       replayed: false,
    //     }));
    //     return;
    //   }
    //   if (state.screen === "result") {
    //     const modified = setRoundCompletedFunction(state);
    //     if (modified) {
    //       state.subtitle = modified.subtitle;
    //       state.currentIndex = modified.currentIndex;
    //       state.round = modified.round;
    //       state.screen = modified.screen;
    //     }
    //     return;
    //   }

    //   const current = state.subtitle[state.currentIndex];
    //   const next = getNextSubtitleItem(state);
    //   if (state.currentIndex <= getLastRoundSubtitlesIndex(state)) {
    //     /**
    //      * This function returns the index of the next subtitle in the array of subtitles.
    //      * It takes the current index of the subtitle and checks if the subtitle's index in the array is equal to the index of the current subtitle plus one.
    //      * If it is, then it returns the index of the current subtitle.
    //      * If it isn't, then it returns the index of the current subtitle.
    //      *
    //      * @param {typeof initialState} state - the current state of the app
    //      * @param {Subtitle} current - the current subtitle
    //      * @returns {number} - the index of the next subtitle
    //      */
    //     if (END_STATES.includes(state.screen)) {
    //       if (next) {
    //         state.currentIndex = next.index;
    //       } else {
    //       }
    //     }
    //     if (!next) {
    //       // This function checks if the current subtitle is completed
    //       // and if so, it checks if the user used help or skipped the subtitle
    //       // if the user used help or skipped the subtitle, it will go to the result screen
    //       // if the user did not use help or skip the subtitle, it will go to the finish screen
    //       // it will also skip the subtitle and set isCompleted to true
    //       state.subtitle[state.currentIndex].skipped = true;
    //       state.subtitle[state.currentIndex].isCompleted = true;
    //       const sentencesCompletedWithHelpUsed =
    //         getFilteredRoundSubtitles(state);
    //       if (sentencesCompletedWithHelpUsed.length === 0) {
    //         localStorage.removeItem(state.url);
    //       }
    //       state.screen = sentencesCompletedWithHelpUsed.length
    //         ? "result"
    //         : "finish";
    //     } else if (next.isCompleted) {
    //       state.screen = next.skipped ? "skipped" : "answer";
    //     } else if (current.skipped || state.screen === "answer") {
    //       state.screen = "input";
    //     } else if (current.isCompleted) {
    //       state.screen = "answer";
    //     } else {
    //       // This code checks if the user has skipped the current subtitle
    //       // and if so, it updates the state and the screen
    //       if (state.subtitle[state.currentIndex].skipped) {
    //         state.subtitle[state.currentIndex].isCompleted = true;
    //         state.screen = "skipped";
    //       }

    //       state.subtitle[state.currentIndex].skipped = true;
    //       state.subtitle[state.currentIndex].isCompleted = true;
    //       state.screen = "skipped";
    //     }
    //   }
    // },
    // setSubtitleFromLocalStorage(state, action) {
    //   if (!action.payload) return;
    //   state.subtitle = state.subtitle.map((el, i) => ({
    //     ...el,
    //     ...action.payload.subtitle[i],
    //   }));
    //   state.currentIndex = action.payload.currentIndex;
    // },
    setQuoteValues(state, action) {
      // Check if startTime is greater than endTime
      if (action.payload.time === "startTime") {
        if (action.payload.value > state.quoteData.endTime) {
          toast.error("Start time cannot be greater than end time");
          console.log("hahaha", JSON.stringify(state.quoteData));
          return;
        }
      }
      if (action.payload.time === "endTime") {
        if (action.payload.value < state.quoteData.startTime) {
          toast.error("End time cannot be less than start time");
          return;
        }
        if (
          state.quoteData.videoDuration > 0 &&
          action.payload.value > state.quoteData.videoDuration
        ) {
          toast.error("End time cannot be greater than video duration");
          return;
        }
      }
      state.quoteData[action.payload.time] = action.payload.value;
    },
    setInitiateQuoteValues(state, action) {
      // initiate
      state.quoteData = { ...state.quoteData, ...action.payload };
    },
    clearQuoteValues(state, action) {
      state.quoteData = initialState.quoteData;
    },
    setQuoteData(state, action) {
      state.quoteData.startTime = action.payload.startTime;
      state.quoteData.endTime = action.payload.endTime;
      state.quoteData.quote = action.payload.quote;
    },
    setQoute(state, action) {
      console.log("Start Time ", action.payload.startTime);
      console.log("Start Time Type ", typeof action.payload.startTime);
      state.quoteData.startTime =
        typeof action.payload.startTime === "string"
          ? convertTimeToSecs(action.payload.startTime)
          : action.payload.startTime;
      state.quoteData.endTime =
        typeof action.payload.endTime === "string"
          ? convertTimeToSecs(action.payload.endTime)
          : action.payload.endTime;
      state.quoteData.quote = action.payload.quotes;
      state.quoteData.tags = action.payload.tags;
      state.quoteData.sTags = action.payload?.sTags ?? [];
      state.quoteData.level = action.payload.difficulty;
    },

    // toggleEditor(state, action) {
    //   state.state = state.state === "player" ? "editor" : "player";
    // },
    // setEditorState(state, action) {
    //   state.state = action.payload;
    // },
    setEdit(state, action) {
      state.editState = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getVideosAsyncThunk.fulfilled, (state, action) => {
  //       state.videos = {
  //         ...action.payload,
  //         data: action.payload.first
  //           ? action.payload.data
  //           : [...state.videos.data, ...action.payload.data],
  //         current_page: state.videos.data.length
  //           ? state.videos.current_page++
  //           : 1,
  //       };
  //     })
  //     .addCase(getVideosForTagAsyncThunk.fulfilled, (state, action) => {
  //       state.pathVideos = {
  //         ...action.payload,
  //         data: action.payload.first
  //           ? action.payload.data
  //           : [...state.pathVideos.data, ...action.payload.data],
  //         current_page: state.pathVideos.data.length
  //           ? state.pathVideos.current_page++
  //           : 1,
  //       };
  //     })
  //     .addCase(getVideoAsyncThunk.fulfilled, (state, action) => {
  //       state.video = action.payload.data;
  //       if (action.payload.patch) {
  //         state[action.payload.patch] = {
  //           ...state[action.payload.patch],
  //           data: state[action.payload.patch].data.concat([
  //             action.payload.data,
  //           ]),
  //         };
  //       }
  //     })
  //     // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
  //     .addMatcher(
  //       // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
  //       isAnyOf(
  //         // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
  //         ...reduxToolKitCaseBuilder([
  //           getVideosAsyncThunk,
  //           getVideosForTagAsyncThunk,
  //         ])
  //       ),
  //       handleLoading
  //     );
  // },
});

export default youtubeSlice.reducer;
export const {
  // setSubtitle,
  // previousSubtitle,
  // setRoundCompleted,
  // changePlay,
  // switchFullScreen,
  // toggleStartLearn,
  // quitLearn,
  // setReplayed,
  // completeSubtitle,
  // setHelpUsed,
  // setSkipped,
  // setScreenState,
  // setSubtitleFromLocalStorage,
  setQuoteValues,
  clearQuoteValues,
  setQuoteData,
  setInitiateQuoteValues,
  setQoute,
  // toggleEditor,
  // setEditorState,
  setEdit,
} = youtubeSlice.actions;
