import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import YouTube from "react-youtube";
import {
  Await,
  defer,
  Link,
  useLoaderData,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { store, useAppSelector } from "redux/store";
import { convertToSecondsFloat } from "./PrepareQuotes";
// import logo from "assets/logo.svg";
import PrepareQuotesHeader from "components/quotes/prepareQuotesHeader";
import QuotesControl from "components/QuotesControll/QuotesControl";
import { getQuotesForTagAsyncThunk } from "redux/pagesSlices/quoteSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col } from "react-bootstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import CirleInfo from "components/CirleInfo";
import { toast } from "react-toastify";

function PlayQuotes() {
  /**
   * @type {any}
   */
  const data = useLoaderData();

  return (
    <Suspense
      fallback={
        <div className="loading-screen w-100 h-100 d-flex justify-content-center align-items-center">
          Loading...
        </div>
      }
    >
      <Await resolve={data?.data}>{(data) => <Learn quotes={data} />}</Await>
    </Suspense>
  );
}

function Learn({ quotes }) {
  // console.log("quotesquotesquotesquotes", quotes)
  const [searchq, setUrlSearch] = useSearchParams();
  const pa = searchq.get("a");
  const a = pa ?? "1";
  const { tagQuotes, loadings, maxQuotes } = useAppSelector((s) => s.quote);
  const isLoading = loadings?.["getQuotesForTagAsyncThunk"];
  const dispatch = useDispatch();
  const { id, name } = useParams();
  const navigate = useNavigate();
  useEffect(()=> {
    let levels = id.split(",").map((i) => +i).sort((a, b) => a - b).join(",");
    localStorage.setItem(`${name}-${levels}`, pa);
  }, [id, name, pa]);

  const localStorageLevel = useMemo(() => {
    let levels = id.split(",").map((i) => +i).sort((a, b) => a - b).join(",");
    return localStorage.getItem(`${name}-${levels}`);
  }, [id, name]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const playerRef = useRef(null);
  const quoteData = useAppSelector((s) => s.youtube.quoteData);
  const { data, loadMore, perPage, a: sParam } = tagQuotes;
  const { startTime, endTime, videoId } =
    data && Array.isArray(data) && data?.length && data?.length >= +a && 0 < +a
      ? { ...data[+a - 1], ...quoteData }
      : { startTime: "00:00:00", endTime: "00:00:00", videoId: "" };
  const [playedCounts, setPlayedCounts] = useState(0);
  const [helpCount, setHelpCount] = useState(0);
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && !isLoading && loadMore) {
      const currentPage = +a;
      const nearestMultipleOf10 = Math.ceil(currentPage / 10) * 10;
      const stepsAwayFromMultipleOf10 = nearestMultipleOf10 - currentPage;
      const newId = id.split(",").map((i) => +i);
      if (stepsAwayFromMultipleOf10 <= 2 && stepsAwayFromMultipleOf10 > 0) {
        dispatch(
          // @ts-ignore
          getQuotesForTagAsyncThunk({
            perPage,
            search: null,
            first: false,
            status: "Approved",
            difficulty: newId,
            collectionName: name,
            cb: (data) => {
              console.log("fetch data");
            },
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a]);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data) &&
      data.length &&
      data.length < +a &&
      !isLoading
    )
      setUrlSearch({ a: "1" });
    if (+pa === 0) setUrlSearch({ a: sParam ?? "1" });
  }, [a]);
  useEffect(() => {
    if(!pa) {
      setUrlSearch({ a: localStorageLevel ?? "1" });
    }
  }, [pa, localStorageLevel]);
  // player handlers

  const onReady = (event) => {
    setPlayer(event.target);
    console.log(convertToSecondsFloat(startTime));
    event.target.seekTo(convertToSecondsFloat(startTime));
    event.target.playVideo();
  };

  const startAgain = () => {
    if (player) {
      setPlayedCounts(playedCounts + 1);
      player.seekTo(convertToSecondsFloat(startTime));
      player.playVideo();
    }
  };
  const handleStateChange = useCallback(
    async (event) => {
      const playerState = event.data;
      console.log(event)
      if (playerState === window?.YT?.PlayerState?.PLAYING) {
        event.target.unMute();
        setIsPlaying(true);
        const startSeconds = await event.target.getCurrentTime();
        const endSeconds = convertToSecondsFloat(endTime);
        const duration = endSeconds - startSeconds;

        const timeoutId = setTimeout(() => {
          playerRef.current.internalPlayer.pauseVideo();
          setIsPlaying(false);
        }, duration * 1000);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    },
    [endTime]
  );

  useEffect(() => {
    if ((!data || (Array.isArray(data) && !data.length)) && !isLoading) {
      // setUrlSearch({ a: "1" });
      navigate(`/`);
      toast.error("No Highlight in this collection");
    }
  }, [data, isLoading, navigate]);

  // Create a function to save the start and end times of a YouTube video to Firebase
  if (isLoading)
    return (
      <div className="loading-screen w-100 h-100 d-flex justify-content-center align-items-center">
        Loading...
      </div>
    );

  if (!data || (Array.isArray(data) && !data.length))
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Col xs={12} className="text-center">
          <h1 style={{ color: "var(--bg-color)" }}>:(</h1>
          <p style={{ color: "var(--bg-color)" }}>
            No Highlight in This difficulty!
          </p>
          <Link to={`/quotes/${+id - 1}/play`} className="me-3">
            <Button>
              <FaAngleDoubleLeft />
            </Button>
          </Link>
          <Link to={`/quotes/${+id + 1}/play`}>
            <Button>
              <FaAngleDoubleRight />
            </Button>
          </Link>
        </Col>
      </div>
    );
  return (
    <div className="play-quotes">
      <PrepareQuotesHeader a={id} data={data?.[+a - 1]} />
      <YouTube
        ref={playerRef}
        className="react-player"
        videoId={videoId}
        opts={{
          height: "390",
          width: "640",
          playerVars: {
            controls: 0,
            fs: 1,
            start: convertToSecondsFloat(startTime),
            end: convertToSecondsFloat(endTime) + 1,
            autoplay: 1,
            mute: 1,
          },
        }}
        onReady={onReady}
        onStateChange={handleStateChange}
      />
      <QuotesControl
        data={data?.[+a - 1]}
        startAgain={startAgain}
        setPlayedCounts={setPlayedCounts}
        playedCounts={playedCounts}
        setHelpCount={setHelpCount}
        helpCount={helpCount}
        isPlaying={isPlaying}
      />
      {screenWidth > 575 && (
        <CirleInfo startAgain={startAgain} startPlay={startAgain} />
      )}
    </div>
  );
}

// const getData = async (id) => {
//   const docRef = doc(db, "quotes", id);
//   const docSnap = await getDoc(docRef);
//   return docSnap.data();
// };
const getData = async (id = "1", a = 10, hash = undefined, collectionName) => {
  const newId = id.split(",").map((i) => +i);
  return new Promise((resolve, reject) => {
    store.dispatch(
      getQuotesForTagAsyncThunk({
        perPage: a,
        search: null,
        first: true,
        status: "Approved",
        difficulty: newId,
        hash,
        collectionName: collectionName,
        cb: (data) => {
          resolve(data);
        },
        errorCb: (error) => {
          reject(error);
        },
      })
    );
  });
};

/**
 * @type {import("react-router-dom").LoaderFunction}
 */
export const loader = ({ params, request }) => {
  const { id, name } = params;
  // const a = searchParams.get("a");
  const url = new URL(request.url);
  const a = url.searchParams.get("a");
  const hash = url.searchParams.get("hash");

  return defer({
    data: getData(id, a && +a >= 10 ? +a : 10, hash, name),
  });
};

export default PlayQuotes;
