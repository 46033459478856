import { toast } from "react-toastify";

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const checkArray = (e) => (Array.isArray(e) ? e : [e]);
export const detectError = (error, dispatch, rejectWithValue) => {
  console.log("error:::::", error);
  toast.error(error?.message ?? error ?? "Some thing went wrong!");
  if (rejectWithValue) {
    return rejectWithValue(error);
  }
};

export const spreadObjValuesNotNull = (ob) => {
  // const keysToParse = ["properties", "levels", "stats"];
  if (typeof ob === "object" && ob) {
    const tempObj = {};
    Object.keys(ob).forEach((key) => {
      // if (keysToParse.includes(key)) {
      //   tempObj[key] = ob[key] == "undefined" ? [] : JSON.parse(ob[key]);
      // } else {
      tempObj[key] = ob[key] ?? "";
      // }
    });
    return tempObj;
  } else {
    return ob;
  }
};

//convert search params to opject
export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export const mapAlterString = (_array, string) => {
  if (Array.isArray(_array) && _array.length > 0) {
    return _array.map((item) => item[string]);
  } else {
    return string;
  }
};
export const subStringNumber = (stirng, numbers) => {
  if (typeof stirng == "string" && stirng.length > numbers) {
    return stirng.substring(1, numbers) + "...";
  } else {
    return stirng;
  }
};
// handle error leading and server params
function handleLoading(state, { meta, payload, type }) {
  const action = type.split("/");
  if (meta?.arg && type.endsWith("/pending")) {
    state.paramsForThunk[action[1]] = meta?.arg;
  }
  if (type.endsWith("/rejected") && payload) {
    state.errorMessages[action[1]] =
      typeof payload === "string"
        ? payload
        : payload?.message ?? "Something went wrong";
    state.errorCodes[action[1]] =
      payload?.code ?? payload?.response?.status ?? 401;
  }
  state.errors[action[1]] = type.endsWith("/rejected");
  state.loadings[action[1]] = type.endsWith("/pending");
}
export { handleLoading };

export const catchAsync = (fn) => (_, api) => {
  return Promise.resolve(fn(_, api)).catch((error) => {
    console.log("catchAsync:", error);
    return detectError(error, api?.dispatch, api?.rejectWithValue);
  });
};
export const reduxToolKitCaseBuilder = (cases) => {
  return cases.flatMap((el) => [el.pending, el.fulfilled, el.rejected]);
};
