import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from "config/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { catchAsync } from "helpers/detectError";

// Create Collection
export const storeCollectionAsyncThunk = createAsyncThunk(
  "collections/storeCollection",
  catchAsync(async ({ data, callBack }) => {
    console.log("Store Collection", data);
    const collectionRef = doc(
      collection(db, "collections"),
      data.name.toLowerCase()
    );
    const collectionSnapshot = await getDoc(collectionRef);
    console.log("collectionSnapshot", collectionSnapshot);
    if (collectionSnapshot.exists()) {
      await updateDoc(collectionRef, { ...data, timestamp: serverTimestamp() });
    } else {
      console.log("New Collection Adding");
      await setDoc(collectionRef, { ...data, timestamp: serverTimestamp() });
    }
    if (callBack) callBack();
    return data;
  })
);

// Get Collection
export const getCollectionAsyncThunk = createAsyncThunk(
  "collections/getCollection",
  catchAsync(async ({ callBack }) => {
    // const collectionRef = await getDocs(collection(db, "collections"));
    const colRef = collection(db, "collections");
    let q = query(
      colRef,
      orderBy("type", "desc"),
      where("type", "!=", "private"),
      orderBy("timestamp", "desc")
    ); // Replace "timestampField" with the actual field you want to order by

    const collectionRef = await getDocs(q);

    const fetchedCollection = [];
    collectionRef.forEach((doc) => {
      fetchedCollection.push({
        _id: doc.id,
        ...doc.data(),
        label: doc.data().name,
      });
    });
    if (callBack) callBack();
    console.log("Fetched Collections", fetchedCollection);
    return fetchedCollection;
  })
);

const initialState = {
  collections: {
    data: [],
    error: null,
    isLoading: false,
    current_page: 1,
    loadMore: true,
  },
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const collectionSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    addCollection: (state, action) => {
      state.collections = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeCollectionAsyncThunk.pending, (state, action) => {
        state.collections.isLoading = true;
      })
      .addCase(storeCollectionAsyncThunk.fulfilled, (state, action) => {
        state.collections = {
          data: state.collections.data.map((collection) => {
            return collection._id === action.payload.name.toLowerCase()
              ? { ...collection, ...action.payload }
              : collection;
          }),
          error: null,
          isLoading: false,
        };
      })
      .addCase(storeCollectionAsyncThunk.rejected, (state, action) => {
        state.collections = {
          data: [],
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(getCollectionAsyncThunk.pending, (state, action) => {
        state.collections.isLoading = true;
      })
      .addCase(getCollectionAsyncThunk.fulfilled, (state, action) => {
        state.collections = {
          data: action.payload,
          error: null,
          isLoading: false,
        };
      })
      .addCase(getCollectionAsyncThunk.rejected, (state, action) => {
        state.collections = {
          data: [],
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export const { addCollection } = collectionSlice.actions;
export default collectionSlice.reducer;
