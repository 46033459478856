// ** Reducers Imports
import quote from "./pagesSlices/quoteSlice";
import path from "./pagesSlices/pathSlice";
import auth from "./pagesSlices/authSlice";
import youtube from "./pagesSlices/youtubeSlice";
import collection from "./pagesSlices/collectionSlice";

import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  youtube,
  quote,
  path,
  auth,
  collection,
});
export default rootReducer;
