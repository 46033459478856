import { useEffect, useState } from "react";
import Sidebar from "components/sidebar";
import { auth } from "config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  getProfileAsyncThunk,
  logout,
  setUser,
} from "redux/pagesSlices/authSlice";
import { useAppSelector } from "redux/store";
import NotFound from "./404";
import { IntroPage } from "./IntroPage";
import Login from "./Login";
import Register from "./Register";
import MorePage from "./more";
import WaitingPage from "./waiting";
import AddQuotes from "./AddQuotes";
import PrepareQuotes from "./PrepareQuotes";
// import Quotes from "./Quotes";
import PlayQuotes, { loader as quoteLoader } from "./playQuotes";
import Auth from "components/auth";
import { AddHighlight } from "./AddHighlight";
import PreventMobile from "./PreventMobile";
import Help from "./Help";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route path="/" element={<Auth />}>
        <Route index element={<IntroPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/help" element={<Help />} />
        <Route index path="app" element={<IntroPage />} />
        {/* <Route path="/quotes/add" element={<AddQuotes />} /> */}
        <Route path="/quotes/add" element={<AddHighlight />} />
        <Route path="/quotes/prepare" element={<PrepareQuotes />} />
        <Route
          path="/quotes/:id/:name/play"
          loader={quoteLoader}
          element={<PlayQuotes />}
        />
        <Route path="/more" element={<MorePage />} />
        <Route path="/waiting" element={<WaitingPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

const allIntroRoute = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<IntroPage />} />)
);

export default function Index() {
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log = () => {};
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        dispatch(setUser({ user }));
        // @ts-ignore
        dispatch(getProfileAsyncThunk());
        // dispatch(getProfileAsyncThunk());
        // setIsLoading(false);
      } else {
        dispatch(logout());
        // setIsLoading(false);
        console.log("not login");
      }
    });
  }, [dispatch]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768); // You can adjust the threshold as needed
    };

    // Initial check
    checkIsMobile();

    // Add an event listener to update isMobile when the window is resized
    window.addEventListener("resize", checkIsMobile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  // if (isMobile) return <PreventMobile />;

  return (
    <>
      <RouterProvider
        router={
          // window.localStorage.getItem("password") !== "false"
          //   ? allIntroRoute
          //   : router
          router
        }
      />
    </>
  );
}
