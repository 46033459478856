import { ExitToApp } from "@mui/icons-material";
import { List, ListItem, ListItemButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import logo from "assets/logo.png";
import { logoutAsyncThunk } from "redux/pagesSlices/authSlice";
import { useAppSelector } from "redux/store";

/**
 * @template T
 * @template U
 * @param {T} a
 * @param {U} b
 * @returns {T | U}
 */
const checkClientWidth = (a, b) => (document.body.clientWidth > 1400 ? a : b);

const FONT_SIZE = checkClientWidth(1, 0.75);
const CustomSvgIcon = ({ isActive, letter, icon }) => {
  return (
    <div
      style={{
        width: checkClientWidth(50, 30),
        height: checkClientWidth(50, 30),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        transition: "all 0.3s ease",
        transform: isActive ? "scale(1.25)" : "scale(1)",
        // backgroundColor: isActive ? "black" : "#1A5F75",
        backgroundColor: isActive ? "transparent" : "#000000",
        color: isActive ? "#000" : "var(--bg-color)",
        border: "2px solid #000",
        fontWeight: 700,
      }}
    >
      <span
        style={{
          fontSize: checkClientWidth(16, 12),
          lineHeight: 1,
        }}
      >
        {icon ?? letter}
      </span>
    </div>
  );
};

const NavItem = ({ isActive, letter, icon, children, ...props }) => (
  <Tooltip
    arrow
    title={
      <span
        style={{
          lineHeight: 2,
          color: "pink",
          fontSize: checkClientWidth(14, 12),
        }}
        className="pt-2"
      >
        {children}
      </span>
    }
    componentsProps={{
      tooltip: { className: "bg-dark", style: { color: `var(--bgColor)` } },
    }}
    placement="right"
  >
    <div>
      <ListItemButton
        style={{
          margin: "0 auto",
          textAlign: "center",
          borderRadius: 10,
          padding: `5px 0`,
        }}
        dense
        {...props}
      >
        <ListItem
          style={{
            fontSize: `${FONT_SIZE}rem`,
            whiteSpace: "nowrap",
            color: "var(--text-color)",
            padding: `${!isActive ? 0 : (1.6 * FONT_SIZE) / 2}rem 0`,
            transition: "all 0.3s ease",
          }}
          className="cursor-pointer w-100 d-flex py-2 flex-column"
        >
          <>
            <CustomSvgIcon icon={icon} letter={letter} isActive={isActive} />
          </>
        </ListItem>
      </ListItemButton>
    </div>
  </Tooltip>
);

const Sidebar = () => {
  const { isLoggedIn, profile } = useAppSelector((s) => s.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const pattern = /quotes\/[a-zA-Z0-9]+\/play/;
  const dispatch = useDispatch();
  if (
    pathname.includes("/video") ||
    pathname.includes("quotes/prepare") ||
    pattern.test(pathname)
  ) return <Outlet />;
  return (
    <div
      style={{
        flex: `1`,
        backgroundColor: "var(--bg-color)",
      }}
      className="d-flex flex-column"
    >
      <div style={{ height: `100vh` }} className="w-100 overflow-hidden d-flex">
        <div
          className="p-3 d-flex flex-column justify-content-center h-100 overflow-auto w-100 "
          style={{ maxWidth: checkClientWidth(180, 120) }}
        >
          <div className="pe-cursor mx-auto">
            <Link to="/app">
              <img src={logo} alt="logo" width={70} />
            </Link>
          </div>

          <div className="d-flex align-items-center">
            <List className="w-100">
              <NavLink to="/app" end style={{ textDecoration: "none" }}>
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    letter="Q"
                  >
                    Highlights
                  </NavItem>
                )}
              </NavLink>
              <NavLink
                // to="/quotes"
                to="/quotes/add"
                // className="disabled-link"
                end
                style={{ textDecoration: "none" }}
              >
                {({ isActive }) => {
                  return (
                    <NavItem isActive={isActive} letter="W">
                      waiting list
                    </NavItem>
                  );
                }}
              </NavLink>
              <NavLink to="/more" end style={{ textDecoration: "none" }}>
                {({ isActive }) => {
                  return (
                    <NavItem isActive={isActive} letter="M">
                      More Stuff
                    </NavItem>
                  );
                }}
              </NavLink>
              {isLoggedIn ? (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      logoutAsyncThunk({
                        callBack: () => navigate("/"),
                      })
                    );
                  }}
                  href="/app"
                  style={{ textDecoration: "none" }}
                >
                  <NavItem
                    isActive={false}
                    letter={<ExitToApp fontSize="inherit" />}
                  >
                    Logout
                  </NavItem>
                </a>
              ) : (
                <NavLink to="/login" end style={{ textDecoration: "none" }}>
                  {({ isActive }) => {
                    return (
                      <NavItem
                        isActive={isActive}
                        letter="L"
                      // letter={<AiOutlineLogin />}
                      >
                        Login
                      </NavItem>
                    );
                  }}
                </NavLink>
              )}
            </List>
          </div>
        </div>
        <div
          className="w-100 h-100"
          id="scrollableDiv"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
        </div>
      </div>
      {/* <CookieConsent /> */}
    </div>
  );
};

export default Sidebar;
