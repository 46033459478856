import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { db } from "config/firebase";
import {
  collection,
  deleteDoc,
  doc,
  endAt,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  catchAsync,
  handleLoading,
  reduxToolKitCaseBuilder,
} from "helpers/detectError";
import customToast from "hooks/customToast";

// get quotes async thunk
export const getQuotesAsyncThunk = createAsyncThunk(
  "quote/getQuotesAsyncThunk",
  catchAsync(async ({ perPage, search, first, cb }, { getState }) => {
    const state = getState();
    var q;
    if (state.quote.quotes.data.length && !first) {
      q = query(
        collection(db, "quotes"),
        ...(search
          ? [
              orderBy("timestamp", "desc"),
              startAt(search.toLowerCase()),
              endAt(search.toLowerCase() + "\uf8ff"),
            ]
          : [orderBy("timestamp", "desc")]),
        startAfter(state.quote.quotes.lastItem),
        limit(perPage ?? 10)
      );
    } else {
      q = query(
        collection(db, "quotes"),
        ...(search
          ? [
              orderBy("timestamp", "desc"),
              startAt(search.toLowerCase()),
              endAt(search.toLowerCase() + "\uf8ff"),
            ]
          : [orderBy("timestamp", "desc")]),
        limit(perPage ?? 10)
      );
    }
    // var q = query(collection(db, "quotes"), limit(perPage ?? 10));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((el) => ({ ...el.data(), id: el.id }));
    const all = {
      loadMore: data.length === +perPage,
      data,
      first: !!first,
      lastItem:
        querySnapshot.size > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : state.quote.quotes.lastItem,
    };
    console.log("all", all);
    if (cb) cb(all);
    return all;
  })
);

export const getQuotesForTagAsyncThunk = createAsyncThunk(
  "quote/getQuotesForTagAsyncThunk",
  catchAsync(
    async (
      {
        perPage: _perPage,
        search,
        first,
        tag,
        status,
        difficulty,
        hash,
        cb,
        collectionName,
      },
      { getState }
    ) => {
      var quote,
        a = null;
      var perPage = _perPage;
      if (hash) {
        const decodedQuotesAndVideoId = decodeURIComponent(hash);

        // Now, extract the original `quotes` and `videoId`
        const videoIdStartIndex = decodedQuotesAndVideoId.lastIndexOf(
          "VIDEOS_ID_SEPARATOR"
        );
        const quotes = decodedQuotesAndVideoId.slice(0, videoIdStartIndex);
        const videoId = decodedQuotesAndVideoId.slice(
          videoIdStartIndex + "VIDEOS_ID_SEPARATOR".length
        );
        const collectionRef = collection(db, "quotes");
        const q = query(
          collectionRef,
          where("quotes", "==", quotes),
          where("videoId", "==", videoId),
          orderBy("timestamp", "asc"),
          limit(1)
        );
        quote = await (await getDocs(q)).docs[0];
      }

      const state = getState();
      if (
        first &&
        JSON.stringify(state.quote.tagQuotes.oldParams) ===
          JSON.stringify({ perPage, search, tag, status, difficulty })
      ) {
        if (cb) cb(state.quote.tagQuotes);
        let collectionIdMatch = false;
        if (
          state.quote &&
          state.quote.tagQuotes &&
          state.quote.tagQuotes.data &&
          state.quote.tagQuotes.data[0] &&
          state.quote.tagQuotes.data[0].collection
        )
          state.quote.tagQuotes.data[0].collection.forEach((c) => {
            if (c.id === collectionName.toLowerCase()) {
              collectionIdMatch = true;
            }
          });
        if (collectionIdMatch) {
          return { ...state.quote.tagQuotes, save: false };
        }
      }
      console.log("tag", {
        perPage,
        search,
        first,
        tag,
        status,
        difficulty,
      });
      var q;
      if (state.quote.tagQuotes.data.length && !first) {
        q = query(
          collection(db, "quotes"),
          ...(status != undefined ? [where("status", "==", status)] : []),
          ...(difficulty ? [where("difficulty", "==", +difficulty)] : []),
          ...(tag && Array.isArray(tag) && tag.length
            ? [where("tags", "array-contains-any", tag)]
            : []),
          ...(search
            ? [
                orderBy("timestamp", "asc"),
                startAt(search.toLowerCase()),
                endAt(search.toLowerCase() + "\uf8ff"),
              ]
            : [orderBy("timestamp", "asc")]),
          startAfter(state.quote.tagQuotes.lastItem),
          limit(perPage ?? 10)
        );
      } else {
        q = query(
          collection(db, "quotes"),
          ...(status != undefined ? [where("status", "==", status)] : []),
          ...(tag && Array.isArray(tag) && tag.length
            ? [where("tags", "array-contains-any", tag)]
            : []),
          ...(difficulty.length > 0
            ? [where("difficulty", "in", difficulty)]
            : []),
          ...(collectionName !== "all"
            ? [
                where("collection", "array-contains", {
                  name: collectionName.replace(/-/g, " "),
                  id: collectionName.toLowerCase(),
                }),
              ]
            : []),
          ...(search
            ? [
                orderBy("timestamp", "asc"),
                startAt(search?.toLowerCase()),
                endAt(search?.toLowerCase() + "\uf8ff"),
              ]
            : [orderBy("timestamp", "asc")]),

          ...(quote ? [endBefore(quote)] : [limit(perPage ?? 10)])
        );
      }
      // var q = query(collection(db, "quotes"), limit(perPage ?? 10));

      const querySnapshot = await getDocs(q);

      const quoteCount = await getCountFromServer(q);

      const data = querySnapshot.docs.map((el, i) => {
        // console.log("Data", el.data());
        return {
          ...el.data(),
          id: el.id,
          ...quoteCount.data(),
        };
      });
      if (quote) {
        data.push({ ...quote.data(), id: quote.id });
        data.forEach((d, index) => {
          if (quote.id === d.id) a = index + 1;
        });
        perPage = data.length;
      }
      const all = {
        ...(a ? { a } : {}),
        save: true,
        oldParams: { perPage, search, tag, status, difficulty },
        loadMore: data.length === +perPage,
        data,
        perPage,
        first: !!first,
        lastItem:
          querySnapshot.size > 0
            ? querySnapshot.docs[querySnapshot.docs.length - 1]
            : state.quote.tagQuotes.lastItem,
      };
      if (cb) cb(all);
      return all;
    }
  )
);

export const getQuoteAsyncThunk = createAsyncThunk(
  "quote/getQuoteAsyncThunk",
  catchAsync(async ({ id, patch, callBack }, { getState }) => {
    const documentRef = doc(db, "Quotes", id);
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data();
      // Process the data or return it as needed
      return { data, patch };
    } else {
      // Document doesn't exist
      console.log("Document not found!");
      throw "Highlight does't exist!";
    }
  })
);
export const deleteQuoteAsyncThunk = createAsyncThunk(
  "quote/deleteQuoteAsyncThunk",
  catchAsync(async ({ id, callBack }) => {
    await deleteDoc(doc(db, "quotes", id));
    // Return the deleted id if needed
    if (callBack) callBack();
    return id;
  })
);
export const updateQuoteAsyncThunk = createAsyncThunk(
  "quote/updateQuoteAsyncThunk",
  catchAsync(
    async ({
      id,
      data,
      videoPlayCountIncrement,
      videoCompleteCountIncrement,
      callBack,
    }) => {
      const quoteRef = doc(db, "quotes", id);
      if (videoPlayCountIncrement || videoCompleteCountIncrement) {
        const currentQoute = await getDoc(quoteRef);
        // console.log("quoteRef", currentQoute.data());
        const data = currentQoute.data();
        const videoPlayCount =
          data.videoPlayCount !== undefined ? data.videoPlayCount + 1 : 1;

        const videoCompleteCount =
          data.videoCompleteCount !== undefined
            ? data.videoCompleteCount + 1
            : 1;

        let updatedData = {
          ...currentQoute.data(),
        };
        if (videoPlayCountIncrement) {
          updatedData = {
            ...updatedData,
            videoPlayCount: videoPlayCount,
            // timestamp: serverTimestamp(),
          };
        } else {
          updatedData = {
            ...updatedData,
            videoCompleteCount: videoCompleteCount,
            // timestamp: serverTimestamp(),
          };
        }
        // console.log("updatedData Count", updatedData);
        await updateDoc(quoteRef, updatedData);
        if (callBack) callBack();

        return { id, updatedData };
      } else {
        // console.log("Old data", data);
        // console.log("id", id);
        // console.log("Updated Data", {
        //   ...data,
        //   timestamp: serverTimestamp(),
        //   id: `${data.videoId}:${data.startTime.split(",")[0]}:${
        //     data.endTime.split(",")[0]
        //   }`,
        // });

        await updateDoc(quoteRef, {
          ...data,
          timestamp: serverTimestamp(),
          id: `${data.videoId}:${data.startTime.split(",")[0]}:${
            data.endTime.split(",")[0]
          }`,
        });

        if (callBack) callBack();

        // Return the updated quoteId if needed
        return { id, data };
      }
    }
  )
);
export const createQuoteAsyncThunk = createAsyncThunk(
  "quote/createQuoteAsyncThunk",
  catchAsync(
    async ({
      data,
      videoPlayCountIncrement,
      videoCompleteCountIncrement,
      callBack,
    }) => {
      const {
        startTime,
        endTime,
        videoId,
        difficulty,
        collection,
        quotes,
        sTags,
      } = data;
      const videosRef = doc(
        db,
        "quotes",
        videoId + ":" + startTime + ":" + endTime
      );
      if (!quotes || quotes === "")
        return customToast.error("quotes is Required!");
      return setDoc(videosRef, {
        startTime,
        endTime,
        videoId,
        collection,
        difficulty,
        quotes,
        index: quotes.toLowerCase(),
        sTags,
        status: "Waiting",
        timestamp: serverTimestamp(),
      })
        .then((e) => {
          if (callBack) callBack();
          customToast.success("your Highlight is saved and under review.");
          console.log("Video times saved successfully.", e);
          // navigate("/app");
        })
        .catch((error) => {
          console.error("Error saving video times: ", error);
        });
    }
  )
);

export const getMaxQuotesAsyncThunk = createAsyncThunk(
  "quote/getMaxQuotesAsyncThunk",
  catchAsync(async ({ callBack, collectionName, difficulty }) => {
    const q = query(
      collection(db, "quotes"),
      where("collection", "array-contains", {
        name: collectionName.split("-").join(" "),
        id: collectionName.toLowerCase(),
      }),
      where("difficulty", "==", +difficulty)
    );
    const querySnapshot = await getCountFromServer(q);
    const data = querySnapshot.data().count;
    if (callBack) callBack(data);
    return data;
  })
);

/**
 * @type {("input" | "answer" | "result" | "finish" | "skipped")[]}
 */
export const END_STATES = ["answer", "result", "finish", "skipped"];

/**
 * @typedef Subtitle
 * @property {number} index
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} text
 * @property {number} round
 * @property {boolean} isCompleted
 * @property {boolean} helpUsed
 * @property {boolean} replayed
 * @property {boolean} skipped
 * @property {number} helpCount
 * @property {number} replayCount
 * @property {number} skippedCount
 */

const initialState = {
  /**
   * @type {Subtitle[]}
   */
  // thumbnail: null,
  subtitle: [],
  progress: {},
  url: "",
  // authorName: "",
  // videoName: "",
  // isPlaying: false,
  // isStarted: false,
  // preventStop: false,
  quote: {},
  quotes: {
    current_page: 1,
    loadMore: true,
    data: [],
  },
  tagQuotes: {
    current_page: 1,
    loadMore: true,
    data: [],
  },

  fullScreenMode: false,
  // savedId: "",
  // currentIndex: 0,
  // finish: false,
  // manager states
  errors: {},
  loadings: {
    createQuoteAsyncThunk: false,
  },
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  round: 1,
  /**
   * @type {"input" | "answer" | "result" | "finish" | "skipped"}
   */
  screen: "input",
  activeRoundSubtitles: [],
  finishIndex: 0,
  maxQuotes: null,
};

/**
 * @param {typeof initialState} state
 * @returns
 */
const getCurrentIndex = (state) =>
  getActiveRoundSubtitles(state).findIndex(
    (item) => item.index === state.currentIndex
  );

/**
 *
 * @param {Partial<typeof initialState>} state
 */
const getActiveRoundSubtitles = (state) =>
  state.subtitle.filter((item) => item.round === state.round);

/**
 * @param {typeof initialState} state
 */
export const getFilteredRoundSubtitles = (state) =>
  getActiveRoundSubtitles(state).filter(
    (el) => el.isCompleted && (el.helpUsed || el.skipped || el.replayed)
  );

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setYoutubeUrlToAdd(state, action) {
      state.url = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuotesAsyncThunk.fulfilled, (state, action) => {
        state.quotes = {
          ...action.payload,
          data: action.payload.first
            ? action.payload.data
            : [...state.quotes.data, ...action.payload.data],
          current_page: state.quotes.data.length
            ? state.quotes.current_page++
            : 1,
        };
      })
      .addCase(updateQuoteAsyncThunk.fulfilled, (state, action) => {
        state.quotes = {
          ...action.payload,
          data: state.quotes.data.map((item) =>
            item.id == action.payload.id
              ? { ...item, ...action.payload.data }
              : item
          ),
        };
      })
      .addCase(deleteQuoteAsyncThunk.fulfilled, (state, action) => {
        state.quotes = {
          ...action.payload,
          data: state.quotes.data.filter((item) => item.id != action.payload),
        };
      })
      .addCase(getQuoteAsyncThunk.fulfilled, (state, action) => {
        state.quote = action.payload.data;
        if (action.payload.patch) {
          state[action.payload.patch] = {
            ...state[action.payload.patch],
            data: state[action.payload.patch].data.concat([
              action.payload.data,
            ]),
          };
        }
      })
      .addCase(getQuotesForTagAsyncThunk.fulfilled, (state, action) => {
        if (action.payload.save) {
          state.tagQuotes = {
            ...action.payload,
            data: action.payload.first
              ? action.payload.data
              : [...state.tagQuotes.data, ...action.payload.data],
            current_page: state.tagQuotes.data.length
              ? state.tagQuotes.current_page++
              : 1,
          };
        }
      })
      .addCase(getMaxQuotesAsyncThunk.fulfilled, (state, action) => {
        state.maxQuotes = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getQuotesAsyncThunk,
            getQuotesForTagAsyncThunk,
            deleteQuoteAsyncThunk,
            createQuoteAsyncThunk,
            updateQuoteAsyncThunk,
            getQuoteAsyncThunk,
            getMaxQuotesAsyncThunk,
          ])
        ),
        handleLoading
      );
  },
});

export default quoteSlice.reducer;
export const { setYoutubeUrlToAdd } = quoteSlice.actions;
