import React from "react";
import { Row } from "react-bootstrap";
export default function WaitingPage() {
  return (
    <Row className="d-flex justify-content-center align-items-center h-100 text-center">
      <h1 className="heading-1 text-center text-capitalize">
        Page Under <br /> construction
      </h1>
    </Row>
  );
}
