import { EditorHeader } from "components/editor/EditorHeader";
import { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";

export default function PrepareQuotesHeader({ a, data }) {
  // console.log("data", data);
  const url = new URL(window.location.href);

  const collectionId = url.pathname.split("/")[3];

  const [collectionName, setCollectioName] = useState("");
  useEffect(() => {
    if (typeof data?.collection === "object") {
      data?.collection?.forEach((data) => {
        if (data.id === collectionId.toLowerCase()) {
          setCollectioName(data.name);
        }
      });
      if (collectionName === "") {
        setCollectioName("All");
      }
    }
  }, [collectionId, collectionName, data?.collection]);
  return (
    <div className="header-section ">
      <section className="header-user p-3">
        <Container fluid>
          {/* <div className="d-flex align-items-center gap-4">
            <Link to="/app" className="section-1">
              <img src={require("../../assets/logo.png")} alt="logo" />
            </Link>

            <div className="numberCircle">{a}</div>
          </div> */}
          <h3 className="text-center text-bgColor m-0 fw-bold text-uppercase">
            {collectionName}
          </h3>
        </Container>
      </section>
      <EditorHeader a={a} data={data} />
    </div>
  );
  // );
}
