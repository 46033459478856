import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
export default function VideoIntroModal() {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [allEarsLink, setAllEarsLink] = useState("");
  useEffect(() => {
    function youtube_parser(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length===11)? match[7] : false;
  }
    const extractVideoIdFromLink = (link) => {
      const id = youtube_parser(link);
      return id;
    };
    // Fetch the "btle" link from Firebase Firestore in the useEffect
    const fetchBtleLink = async () => {
      try {
        const btleDocRef = doc(db, "tutorials", "all-ears");
        const btleDocSnapshot = await getDoc(btleDocRef);
        console.log("btleDocSnapshot: ", btleDocSnapshot.data());
        if (btleDocSnapshot.exists()) {
          const btleData = btleDocSnapshot.data();
          console.log("btleDocSnapshot: ", btleData);
          const videoId = extractVideoIdFromLink(btleData.link);
          console.log("videoId: ", videoId);
          const embedLink = `https://www.youtube.com/embed/${videoId}`;
          setAllEarsLink(embedLink);
        }
      } catch (error) {
        console.error("Error fetching BTLE link:", error);
      }
    };

    fetchBtleLink();
  }, []);
  return (
    <>
      <button
        className="btn btn-dark bg-black rounded-pill text-uppercase text-bgColor heading-4 px-5 py-1 fw-bold mb-5"
        onClick={handleShowModal}
      >
        HOW IT WORKS
      </button>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        // backdrop="static"
        size="lg"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="heading-5 text-uppercase">
            All Ears
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="100%"
            src={allEarsLink}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            loading="lazy"
            allowFullScreen
            style={{
              aspectRatio: "16/9",
            }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}
