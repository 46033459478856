import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { db, storage } from "config/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  catchAsync,
  handleLoading,
  reduxToolKitCaseBuilder,
} from "helpers/detectError";
import { toast } from "react-toastify";
// get paths async thunk

const uploadThumbnail = async (image, name) => {
  console.log(image);
  const mountainsRef = ref(storage, `images/${name}`);
  const uploadedImage = await uploadBytes(mountainsRef, image.raw);
  // console.log('uploadedImage', uploadedImage)
  const imageUrl = await getDownloadURL(mountainsRef);
  console.log("imageUrl:::", imageUrl);
  console.log("uploadedImage:::", uploadedImage);
  return imageUrl;
};

export const createPathAsyncThunk = createAsyncThunk(
  "path/createPathAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const docRef = doc(collection(db, "paths"), data.name);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      toast.error(`Document already exists with name: ${data.name}`);
      if (callBack) callBack();
      return;
    } else {
      const imageUrl = await uploadThumbnail(data.image, data.name);
      // Create a new document with the predefined ID
      await setDoc(docRef, { name: data.name, image: imageUrl, timestamp: serverTimestamp(), });
      toast.success("New document created with id:", data.name);
    }
    // const entryRef = await addDoc(collection(db, "paths"), data);
    const entryId = docRef.id;
    // Return the created entry ID if needed
    if (callBack) callBack(entryId);
    return entryId;
  })
);
export const getPathsAsyncThunk = createAsyncThunk(
  "path/getPathsAsyncThunk",
  catchAsync(async ({ perPage, search, first }, { getState }) => {
    const state = getState();
    var q;
    if (state.path.paths.data.length && !first) {
      q = query(
        collection(db, "paths"),
        ...(search
          ? [
            orderBy("timestamp"),
            startAt(search.toLowerCase()),
            endAt(search.toLowerCase() + "\uf8ff"),
          ]
          : [orderBy("timestamp", "desc")]),
        startAfter(state.path.paths.lastItem),
        limit(perPage ?? 10)
      );
    } else {
      q = query(
        collection(db, "paths"),
        ...(search
          ? [
            orderBy("timestamp"),
            startAt(search.toLowerCase()),
            endAt(search.toLowerCase() + "\uf8ff"),
          ]
          : [orderBy("timestamp", "desc")]),
        limit(perPage ?? 10)
      );
    }
    // var q = query(collection(db, "paths"), limit(perPage ?? 10));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((el) => ({ ...el.data(), id: el.id }));
    const all = {
      loadMore: data.length === +perPage,
      data,
      first: !!first,
      lastItem:
        querySnapshot.size > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : state.path.paths.lastItem,
    };
    console.log("all", all)
    return all;
  })
);
export const getAllPathsAsyncThunk = createAsyncThunk(
  "path/getAllPathsAsyncThunk",
  catchAsync(async (_, { getState }) => {
    const state = getState();
    var q = query(collection(db, "paths"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((el) => ({ ...el.data(), id: el.id }));
    return data;
  })
);
export const getPathsForTagAsyncThunk = createAsyncThunk(
  "path/getPathsForTagAsyncThunk",
  catchAsync(async ({ perPage, search, first, tag }, { getState }) => {
    const state = getState();
    var q;
    if (state.path.tagPaths.data.length && !first) {
      q = query(
        collection(db, "paths"),
        ...(tag ? [where('tags', 'array-contains', tag),] : []),
        ...(search
          ? [
            orderBy("timestamp"),
            startAt(search.toLowerCase()),
            endAt(search.toLowerCase() + "\uf8ff"),
          ]
          : [orderBy("timestamp", "desc")]),
        startAfter(state.path.tagPaths.lastItem),
        limit(perPage ?? 10)
      );
    } else {
      q = query(
        collection(db, "paths"),
        ...(tag ? [where('tags', 'array-contains', tag),] : []),
        ...(search
          ? [
            orderBy("timestamp"),
            startAt(search.toLowerCase()),
            endAt(search.toLowerCase() + "\uf8ff"),
          ]
          : [orderBy("timestamp", "desc")]),
        limit(perPage ?? 10)
      );
    }
    // var q = query(collection(db, "paths"), limit(perPage ?? 10));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((el) => ({ ...el.data(), id: el.id }));
    const all = {
      loadMore: data.length === +perPage,
      data,
      first: !!first,
      lastItem:
        querySnapshot.size > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : state.path.tagPaths.lastItem,
    };
    console.log("all", all)
    return all;
  })
);
export const deletePathAsyncThunk = createAsyncThunk(
  "path/deletePathAsyncThunk",
  catchAsync(async ({ id, callBack }) => {
    await deleteDoc(doc(db, "paths", id));
    // Return the deleted id if needed
    if (callBack) callBack()
    return id;
  })
);
export const updatePathAsyncThunk = createAsyncThunk(
  "path/updatePathAsyncThunk",
  catchAsync(async ({ id, data, callBack }) => {
    const pathRef = doc(db, "paths", id);
    const path = await updateDoc(pathRef, {
      ...data
    });
    console.log("path", path)
    if (callBack) callBack()

    // Return the updated pathId if needed
    return { id, data };
  })
);

/**
 * @type {("input" | "answer" | "result" | "finish" | "skipped")[]}
 */
export const END_STATES = ["answer", "result", "finish", "skipped"];

/**
 * @typedef Subtitle
 * @property {number} index
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} text
 * @property {number} round
 * @property {boolean} isCompleted
 * @property {boolean} helpUsed
 * @property {boolean} replayed
 * @property {boolean} skipped
 * @property {number} helpCount
 * @property {number} replayCount
 * @property {number} skippedCount
 */

const initialState = {
  /**
   * @type {Subtitle[]}
   */
  subtitle: [],
  thumbnail: null,
  progress: {},
  url: "",
  authorName: "",
  videoName: "",
  isPlaying: false,
  isStarted: false,
  preventStop: false,
  allPaths: [],
  paths: {
    current_page: 1,
    loadMore: true,
    data: [],
  },
  tagPaths: {
    current_page: 1,
    loadMore: true,
    data: [],
  },
  fullScreenMode: false,
  savedId: "",
  currentIndex: 0,
  finish: false,
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  round: 1,
  /**
   * @type {"input" | "answer" | "result" | "finish" | "skipped"}
   */
  screen: "input",
  activeRoundSubtitles: [],
  finishIndex: 0,
};

/**
 * @param {typeof initialState} state
 * @returns
 */
const getCurrentIndex = (state) =>
  getActiveRoundSubtitles(state).findIndex(
    (item) => item.index === state.currentIndex
  );

/**
 *
 * @param {Partial<typeof initialState>} state
 */
const getActiveRoundSubtitles = (state) =>
  state.subtitle.filter((item) => item.round === state.round);

/**
 * @param {typeof initialState} state
 */
export const getFilteredRoundSubtitles = (state) =>
  getActiveRoundSubtitles(state).filter(
    (el) => el.isCompleted && (el.helpUsed || el.skipped || el.replayed)
  );

const pathSlice = createSlice({
  name: "path",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(getPathsAsyncThunk.fulfilled, (state, action) => {
        state.paths = {
          ...action.payload,
          data: action.payload.first
            ? action.payload.data
            : [...state.paths.data, ...action.payload.data],
          current_page: state.paths.data.length
            ? state.paths.current_page++
            : 1,
        };
      })
      .addCase(getAllPathsAsyncThunk.fulfilled, (state, action) => {
        state.allPaths = action.payload;
      })
      .addCase(updatePathAsyncThunk.fulfilled, (state, action) => {
        state.paths = {
          ...action.payload,
          data: state.paths.data.map(item => item.id == action.payload.id ? { ...item, ...action.payload.data } : item)
        };
      })
      .addCase(deletePathAsyncThunk.fulfilled, (state, action) => {
        state.paths = {
          ...action.payload,
          data: state.paths.data.filter(item => item.id != action.payload)
        };
      })
      .addCase(getPathsForTagAsyncThunk.fulfilled, (state, action) => {
        state.tagPaths = {
          ...action.payload,
          data: action.payload.first
            ? action.payload.data
            : [...state.tagPaths.data, ...action.payload.data],
          current_page: state.tagPaths.data.length
            ? state.tagPaths.current_page++
            : 1,
        };
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createPathAsyncThunk,
            getPathsAsyncThunk,
            getPathsForTagAsyncThunk,
            deletePathAsyncThunk,
            updatePathAsyncThunk
          ])
        ),
        handleLoading
      );
  },
});

export default pathSlice.reducer;
