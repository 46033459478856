export const convertTimeToSecs = (time = "00:00:00.00") => {
  const [hh, mm, ss] = time.split(":");
  const secs = (+hh * 60) + (+mm * 60) + +ss.replace(",", ".");
  return secs;
};

export const convertSecsToTime = (secs = 0) => {
  const hh = Math.floor(secs / 3600);
  const mm = Math.floor((secs - hh * 3600) / 60);
  const ss = secs - hh * 3600 - mm * 60;
  // check if need to add 0 create a separate function
  function addZero(num = 0, isFixed = false) {
    if (isFixed) {
      return num < 10 ? `0${num.toFixed(2)}` : num.toFixed(2);
    }
    return num < 10 ? `0${num}` : num.toString();
  }
  return `${addZero(hh)}:${addZero(mm)}:${addZero(ss, true)
    .split(".")
    .join(",")}`;
};
