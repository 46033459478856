import { initalLevels } from "components/CollectionNameSection";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getCollectionAsyncThunk } from "redux/pagesSlices/collectionSlice";
import { difficulties } from "pages/PrepareQuotes";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { useAppSelector } from "redux/store";
import { db } from "config/firebase";
import { toast } from "react-toastify";
export default function CollectionSetManage({ data }) {
  const dispatch = useDispatch();
  const { collections } = useAppSelector((s) => s.collection);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const url = useMemo(
    () => new URL(`${window.location.origin}${location.pathname}`),
    [location.pathname]
  );
  // const url = new URL(window.location.href);
  const collectionId = url.pathname.split("/")[3];
  const { id, name } = useParams();
  const newId = id.split(",").map((i) => +i);

  const [selectedCollection, setSelectedCollection] = useState({});
  useEffect(() => {
    if (typeof data?.collection === "object") {
      const item =
        data?.collection?.find((data) => {
          return data.id === collectionId.toLowerCase();
        }) || {};
      console.log("item: ", item);
      setSelectedCollection(item);
    }
  }, [collectionId, data?.collection]);
  useEffect(() => {
    dispatch(getCollectionAsyncThunk({}));
  }, [dispatch, collections?.data?.length]);
  const [selectedLevel, setSelectedLevel] = useState(newId);
  // const [selectedCollection] = useState({});
  const [dataFatched, setDataFatched] = useState(false);
  const [levelsQoutes, setLevelsQoutes] = useState(initalLevels);

  const navigate = useNavigate();
  const handleNavigation = useCallback(
    (newLevels, newCollection) => {
      // if (showModal) setShowPasswordModal(true);
      // else {
      navigate(
        `/quotes/${newLevels}/${
          newCollection.name.replace(/ /g, "-") || "all"
        }/play/`
      );
      // }
    },
    [navigate, selectedLevel]
  );
  const getCollectionSet = async (item) => {
    try {
      if (dataFatched) return;
      // setLevelsQoutes(initalLevels);
      // setSelectedLevel([]);
      if (!selectedCollection?.name) return;
      setLoading(true);
      const qouteRef = collection(db, "quotes");
      console.log({
        name: selectedCollection.name,
        id: selectedCollection.id,
      });
      const collectionQuery = query(
        qouteRef,
        where("collection", "array-contains", {
          name: selectedCollection.name,
          id: selectedCollection.id,
        })
      );
      console.log("collectionQuery");
      const totalQoute = await getCountFromServer(collectionQuery);
      console.log("totalQoute: ", totalQoute.data().count);

      const difficultyCounts = { ...initalLevels };
      const selectDefaultLevels = [];
      const difficultyPromises = [1, 2, 3, 4, 5].map(async (difficulty) => {
        const difficultyQuery = query(
          collectionQuery,
          where("difficulty", "==", difficulty)
        );
        const count = await getCountFromServer(difficultyQuery);
        difficultyCounts[difficulty] = count.data().count;
        if (difficulty > 1 && difficulty < 5) {
          if (count.data().count > 0) {
            selectDefaultLevels.push(difficulty);
          }
        }
      });
      await Promise.all(difficultyPromises);
      setLoading(false);
      const levels = id.split(",").map((i) => +i);
      const newLevels = levels.filter((i) => {
        return difficultyCounts[i] > 0;
      });
      if (newLevels.join(",") !== levels.join(","))
        navigate(
          `/quotes/${
            newLevels.length > 0 ? newLevels : "3"
          }/${name}/play`
        );
      if(newLevels.length === 0) {
        navigate("/?quit=true")
      }
      setLevelsQoutes(difficultyCounts);
      setDataFatched(true);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    // @ts-ignore
    localStorage.setItem("progress", url);
  }, [selectedCollection, selectedLevel, url]);
  useEffect(() => {
    getCollectionSet();
  }, [selectedCollection, selectedLevel]);
  const handlePlay = useCallback(handleNavigation, [handleNavigation]);

  const handleLevel = (item) => {
    let newLevels = [];
    if (selectedLevel.includes(item)) {
      newLevels = selectedLevel.filter((i) => i !== item);
    } else {
      newLevels = [...selectedLevel, item];
    }
    setSelectedLevel(newLevels);
    getCollectionSet(item);
    handlePlay(newLevels, selectedCollection);
  };
  return (
    <div className="collections-level-controll">
      <Dropdown>
        <Dropdown.Toggle
          style={{
            backgroundColor: "var(--text-color)",
            color: "var(--bg-color)",
            fontSize: "28px",
          }}
          className="border-0 p-0 btn-big fw-bold"
          id="dropdown-basic"
        >
          {selectedCollection?.name}&nbsp;
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            backgroundColor: "var(--text-color)",
            color: "var(--bg-color)",
            fontSize: "22px",
          }}
        >
          {collections.data.map((item, index) => (
            <Dropdown.Item
              key={index}
              style={{
                backgroundColor: "var(--text-color)",
                color: "var(--bg-color)",
                fontSize: "22px",
              }}
              // href={item.name}
              onClick={() => handlePlay(selectedLevel, item)}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center">
        {difficulties.map((item, i) => (
          <button
            key={i}
            className={`circle-btn  border-1 fw-bold  ${
              newId.includes(item) ? "active" : ""
            }`}
            onClick={() => handleLevel(item)}
            style={{
              opacity: levelsQoutes[item] === 0 ? 0.5 : 1,
            }}
            disabled={levelsQoutes[item] === 0}
          >
            {item}
          </button>
        ))}
      </div>
      <h1 className="level-description">CHOOSE YOUR LEVEL</h1>
    </div>
  );
}
