import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { auth, db, storage } from "config/firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  deleteField,
} from "firebase/firestore";
// import { getToken } from "firebase/messaging";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  catchAsync,
  handleLoading,
  reduxToolKitCaseBuilder,
} from "helpers/detectError";
import { toast } from "react-toastify";

// login With Google async thunk
export const loginWithGoogleAsyncThunk = createAsyncThunk(
  "auth/loginWithGoogleAsyncThunk",
  catchAsync(async ({ handleToggleModal }, _) => {
    const provider = new GoogleAuthProvider();
    const registeredUser = await signInWithPopup(auth, provider);
    // const registeredUser = await createUserWithEmailAndPassword(auth, data.email, data.password)
    // const updatedUser = await updateProfile(auth.currentUser, {
    //   displayName: data.firstName + " " + data.lastName
    // })
    const profileRef = collection(db, "profile");
    const profile = await setDoc(doc(profileRef, registeredUser.user.uid), {
      userName: registeredUser.user.displayName.split(" ")?.[1],
      email: registeredUser.user.email,
      createdAt: serverTimestamp(),
    });
    // console.log('user:', registeredUser)
    // console.log('updatedUser:', updatedUser)
    // console.log('profile:', profile)
    handleToggleModal();
    return {
      registeredUser,
      profile,
    };
  })
);
// forgot With Google async thunk
export const sendPasswordResetEmailAsyncThunk = createAsyncThunk(
  "auth/sendPasswordResetEmailAsyncThunk",
  catchAsync(async ({ email }, _) => {
    console.log("email:::", email);
    const result = await sendPasswordResetEmail(auth, email)
      .then((r) => console.log("res::::", r))
      .catch((err) => console.log("err:", err));
    console.log("result:", result);
    toast.success(
      "Reset Mail has been sent to your email address if you dont get it check in spam."
    );
    return {
      result,
    };
  })
);
// register async thunk
export const registerAsyncThunk = createAsyncThunk(
  "auth/registerAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    // console.log("parma:", data)
    const registeredUser = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );
    const updatedUser = await updateProfile(auth.currentUser, {
      displayName: data.userName,
    });
    const profileRef = collection(db, "profile");
    const profile = await setDoc(doc(profileRef, registeredUser.user.uid), {
      userName: data.userName,
      email: data.email,
      role: "user",
      timestamp: serverTimestamp(),
    });
    if (callBack) callBack();
    dispatch(getProfileAsyncThunk());
    return {
      registeredUser,
      profile,
    };
  })
);

// login async thunk
export const loginAsyncThunk = createAsyncThunk(
  "auth/loginAsyncThunk",
  catchAsync(async ({ data, callBack }, _) => {
    // console.log("parma:", data)
    const user = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );
    console.log("user:", user);
    if (callBack) callBack();
    toast.success(`Wellcome back ${user.user.displayName}.`);

    return user;
  })
);
// get user settings async thunk
export const getSettingsAsyncThunk = createAsyncThunk(
  "auth/getSettingsAsyncThunk",
  catchAsync(async (_, { getState, dispatch }) => {
    const state = getState();
    // console.log('state:', state.auth.uid)
    const docRef = doc(db, "settings", state.auth.uid);
    const settings = await getDoc(docRef);
    if (settings.exists()) {
      // console.log('settings:', settings.data())
      return settings.data();
    }
  })
);
// get user settings async thunk
export const setSettingsAsyncThunk = createAsyncThunk(
  "auth/setSettingsAsyncThunk",
  catchAsync(async ({ data, callBack, ...everything }, { getState }) => {
    const state = getState();
    const docRef = doc(db, "settings", state.auth.uid);
    await setDoc(docRef, data, { merge: true });
    // console.log('settings is created/updated:', settings)
    if (callBack) callBack();
  })
);
// get profile async thunk
export const getProfileAsyncThunk = createAsyncThunk(
  "auth/getProfileAsyncThunk",
  catchAsync(async (_, { getState }) => {
    const state = getState();
    // console.log('state:', state.auth.uid)
    const docRef = doc(db, "profile", state.auth.uid);
    const profile = await getDoc(docRef);
    if (profile.exists()) {
      console.log("profile:", profile.data());
      return profile.data();
    }
    // console.log('Profile is not updated:', profile)
    throw { message: "Profile is not updated", code: 401 };
  })
);
// create profile async thunk
export const createProfileAsyncThunk = createAsyncThunk(
  "auth/createProfileAsyncThunk",
  catchAsync(async ({ data, image, callBack }, { getState, dispatch }) => {
    const state = getState();
    // console.log('state:', state.auth.uid)
    //
    var imageUrl;
    if (image) {
      // console.log('going to upload image')
      const mountainsRef = ref(storage, `images/${image?.name}`);
      const uploadedImage = await uploadBytes(mountainsRef, image);
      // console.log('uploadedImage', uploadedImage)
      imageUrl = await getDownloadURL(mountainsRef);
      // console.log('imageUrl', imageUrl)
    }

    //
    // console.log('parmans:::', data)
    const profileRef = collection(db, "profile");
    // if(imageUrl) data['imageUrl']=imageUrl
    const savedProfile = await setDoc(
      doc(profileRef, state.auth.uid),
      { ...data, ...(imageUrl && { imageUrl }) },
      { merge: true }
    );
    // console.log('savedProfile:', savedProfile)
    toast.success("Profile Updated Successfully.");
    dispatch(getProfileAsyncThunk());
    if (callBack) callBack();
    return savedProfile;
  })
);
// logout async thunk
export const logoutAsyncThunk = createAsyncThunk(
  "auth/logoutAsyncThunk",
  catchAsync(async ({ callBack }, _) => {
    // if (Notification.permission === "granted") {
    //   const token = await getToken(messaging);
    //   if (token) {
    //     setDoc(
    //       doc(db, "settings", auth.currentUser.uid),
    //       {
    //         [token]: deleteField()
    //       },
    //       { merge: true }
    //     );
    //   }
    // }
    const res = await signOut(auth);
    // console.log('res', res)
    if (callBack) callBack("/");
    return res;
  })
);

const initialState = {
  //states
  user: {},
  profile: {},
  isLoggedIn: false,
  _tokenResponse: {},
  uid: null,
  url: "",
  savedId: "",
  currentIndex: 0,
  finish: false,
  settings: {},
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload.user;
      state.uid = action.payload.user.uid;
      state.isLoggedIn = true;
    },
    logout(state, action) {
      state.user = {};
      state.isLoggedIn = false;
      state.uid = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload.registeredUser.user;
        state.profile = action.payload.profile;
      })
      .addCase(loginWithGoogleAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload.registeredUser.user;
        state.profile = action.payload.profile;
      })
      .addCase(loginAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state._tokenResponse = action.payload._tokenResponse;
        state.isLoggedIn = true;
        state.uid = action.payload.user.uid;
      })
      .addCase(logoutAsyncThunk.fulfilled, (state, action) => {
        state.profile = {};
        state.user = {};
        state._tokenResponse = {};
        state.isLoggedIn = false;
        state.uid = null;
      })
      .addCase(getProfileAsyncThunk.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(getSettingsAsyncThunk.fulfilled, (state, action) => {
        state.settings = action.payload;
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            registerAsyncThunk,
            sendPasswordResetEmailAsyncThunk,
            loginAsyncThunk,
            logoutAsyncThunk,
            createProfileAsyncThunk,
            getProfileAsyncThunk,
            loginWithGoogleAsyncThunk,
            getSettingsAsyncThunk,
            setSettingsAsyncThunk,
          ])
        ),
        handleLoading
      );
  },
});

export default authSlice.reducer;
export const { setUser, logout } = authSlice.actions;
