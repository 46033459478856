import { IntroHeader } from "components/IntroHeader";
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Help() {
  const [allEarLink, setAllEarLink] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // Fetch the "allEar" link from Firebase Firestore in the useEffect
    const fetchAllEarLink = async () => {
      try {
        const allEarDocRef = doc(db, "tutorials", "all-ear");
        const allEarDocSnapshot = await getDoc(allEarDocRef);

        if (allEarDocSnapshot.exists()) {
          const allEarData = allEarDocSnapshot.data();
          const videoId = extractVideoIdFromLink(allEarData.link);
          const embedLink = `https://www.youtube.com/embed/${videoId}`;
          setAllEarLink(embedLink);
        }
      } catch (error) {
        console.error("Error fetching BTLE link:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllEarLink();
  }, []);

  const extractVideoIdFromLink = (link) => {
    const url = new URL(link);
    const params = new URLSearchParams(url.search);
    return params.get("v");
  };

  return (
    <div 
    className="p-3"
     style={{height:"97vh"}}
      >

      <IntroHeader />
      <Container
        className="py-5 d-flex flex-column"
        style={{
          height: "100%",
          color: "var(--bg-color)",
        }}
      >
        <div className="text-center">
          <h1 className="main-heading mb-4" style={{ fontSize: "24px" }}>
            Help!
          </h1>
          <p className="paragraph mb-5">
            You can learn how to use the app. <br />
          </p>
        </div>
        <div
          className="flex-grow-1"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <iframe
              width="80%" // Adjust the width as needed
              height="80%" // Adjust the height to maintain aspect ratio
              loading="lazy"
              src={allEarLink} // Use the fetched "allEarLink" as the source
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </Container>
    </div>
  );
}
