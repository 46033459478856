import fullScreenIcon from "assets/icons/full-screen.svg";
import youtubeIcon from "assets/icons/youtube-video.svg";
import alarmClockIcon from "assets/icons/alarm-clock.svg";
import helpIcon from "assets/icons/help.svg";
import loginIcon from "assets/icons/login.svg";
import plusIcon from "assets/icons/plus.svg";
import closeIcon from "assets/icons/close.svg";
// import { difficulties } from "pages/PrepareQuotes";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HoverSection } from "components/HoverSection";
import { useEffect, useState } from "react";
import { logoutAsyncThunk } from "redux/pagesSlices/authSlice";
import ReactTooltip from "react-tooltip";
import CollectionSetManage from "./collectionSetManage";

export const EditorHeader = ({ a, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, profile } = useSelector((s) => s.auth);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [mobileScreen, setMobileScreen] = useState(window.innerWidth < 575.98);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setMobileScreen(window.innerWidth < 575.98);
    });
  }, []);

  const toggleInfoSection = () => {
    setShowInfo(!showInfo);
  };

  function fullscreenchanged(event) {
    if (document.fullscreenElement) {
      setIsFullScreenMode(true);
    } else {
      setIsFullScreenMode(false);
    }
  }
  useEffect(() => {
    document.addEventListener("fullscreenchange", fullscreenchanged);
  }, []);

  const handleLogout = () => {
    dispatch(
      logoutAsyncThunk({
        callBack: () => navigate("/"),
      })
    );
  };
  return (
    <>
      <section className="header-admin p-3">
        <Container fluid>
          <div className="d-flex justify-content-between ">
            <section className="d-none d-sm-block">
              <div className="d-flex align-items-center gap-2">
                <Link
                  onClick={() => {
                    localStorage.removeItem("progress");
                  }}
                  to={"/?quit=true"}
                  className="circle-btn p-1"
                  data-tip
                  data-for="close-tooltip"
                >
                  <img src={closeIcon} alt="Close" className="w-100 h-100" />
                </Link>
                <button
                  className="circle-btn"
                  data-tip
                  data-for="f-screen-tooltip"
                  onClick={() => {
                    // dispatch(switchFullScreen())
                    if (isFullScreenMode) {
                      document.exitFullscreen();
                    } else {
                      document.body.requestFullscreen();
                    }
                  }}
                >
                  <img
                    src={fullScreenIcon}
                    alt="full screen"
                    className="w-100 h-100"
                  />
                </button>
                <button
                  className="circle-btn hover-button"
                  data-tip
                  data-for="info-tooltip"
                  onClick={mobileScreen ? toggleInfoSection : null}
                >
                  <img src={helpIcon} alt="Help" className="w-100 h-100" />
                  {!mobileScreen && (
                    <HoverSection>
                      TYPE WHAT YOU HEAR IN THE BOX BELOW <br />
                      PLAY AS MANY TIMES AS YOU NEED <br />
                      TRAIN YOUR HEARING AND FOCUS <br />
                      ENTER to play <br />
                      ARROW UP for HELP <br />
                      ARROW DOWN to SKIP <br />
                      SHIFT ENTER to BLINK
                      <br />
                      CTR ENTER / CMD RETURN to TRANSLATE
                    </HoverSection>
                  )}
                </button>
                <a
                  href="https://thetime.pl/"
                  data-tip
                  data-for="other-tooltip"
                  className="circle-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={alarmClockIcon}
                    alt="alarm Clock"
                    className="w-100 h-100"
                  />
                </a>
              </div>
            </section>
            <div className="w-100">
              <CollectionSetManage data={data} />
            </div>
            {/* <section className="col-md-8 order-2 order-md-1">
              <div className="d-flex align-items-center justify-content-center gap-2">
                {difficulties.map((item, i) => (
                  <Link
                    key={i}
                    to={`/quotes/${item}/play`}
                    className={`text-decoration-none circle-btn circle-btn-large ${
                      parseInt(a) === item ? "active" : "hover-button"
                    }`}
                    data-tip
                    data-for={parseInt(a) !== item && "other-tooltip"}
                  >
                    {item}
                    {parseInt(a) !== item && (
                      <HoverSection>
                        THERE ARE 5 LEVELS OF DIFFICULTY. <br />
                        CHOOSE THE ONE THAT IS THE BEST FOR YOU. <br />
                        NOT TOO EASY BUT NOT TOO HARD. <br />
                        THE LEVELS ARE BASED ON USERS’ STATISTICS! <br />
                        THE DIFFICULTY LEVEL IS BASED ON A MIX OF PROBLEMS
                        PEOPLE HAVE: <br />
                        HEARING, FOCUS, ACCENTS AND KNOWLEDGE ABOUT THE LANGUAGE
                      </HoverSection>
                    )}
                  </Link>
                ))}
              </div>
              {profile.role === "admin" && (
                <h4 className="text-bgColor heading-4 text-center mt-2 mb-0">
                  {data?.count || 0}
                </h4>
              )}
            </section> */}
            <section className="d-none d-sm-block">
              <div className="d-flex align-items-center gap-2">
                <a
                  href={
                    `https://www.youtube.com/watch?v=${data?.videoId}` || ""
                  }
                  rel="noreferrer"
                  target={"_blank"}
                  className="circle-btn p-1"
                  data-tip
                  data-for="watch-on-youtube-tooltip"
                >
                  <img
                    src={youtubeIcon}
                    alt="youtube Icon"
                    className="w-100 h-100"
                  />
                </a>
                {!isLoggedIn ? (
                  <Link
                    to="/login"
                    className="circle-btn p-0"
                    data-tip
                    data-for="login-tooltip"
                  >
                    <img src={loginIcon} alt="Login" className="w-100 h-100" />
                  </Link>
                ) : (
                  <button
                    className="circle-btn p-0"
                    onClick={handleLogout}
                    data-tip
                    data-for="logout-tooltip"
                  >
                    <img src={loginIcon} alt="logout" className="w-100 h-100" />
                  </button>
                )}
                <Link
                  to="/quotes/add"
                  className="circle-btn p-1"
                  data-tip
                  data-for="add-quotes-tooltip"
                >
                  <img src={plusIcon} alt="Plus" className="w-100 h-100" />
                </Link>
              </div>
            </section>
          </div>

          <section className="position-fixed bottom-0 start-0 w-100 d-sm-none p-4 bg-black">
            <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
              <Link
                onClick={() => {
                  localStorage.removeItem("progress");
                }}
                to={"/?quit=true"}
                className="circle-btn p-1"
                data-tip
                data-for="close-tooltip"
              >
                <img src={closeIcon} alt="Close" className="w-100 h-100" />
              </Link>
              <button
                className="circle-btn"
                data-tip
                data-for="f-screen-tooltip"
                onClick={() => {
                  // dispatch(switchFullScreen())
                  if (isFullScreenMode) {
                    document.exitFullscreen();
                  } else {
                    document.body.requestFullscreen();
                  }
                }}
              >
                <img
                  src={fullScreenIcon}
                  alt="full screen"
                  className="w-100 h-100"
                />
              </button>
              <button
                className="circle-btn hover-button"
                data-tip
                data-for="info-tooltip"
                onClick={mobileScreen ? toggleInfoSection : null}
              >
                <img src={helpIcon} alt="Help" className="w-100 h-100" />
                {!mobileScreen && (
                  <HoverSection>
                    TYPE WHAT YOU HEAR IN THE BOX BELOW <br />
                    PLAY AS MANY TIMES AS YOU NEED <br />
                    TRAIN YOUR HEARING AND FOCUS <br />
                    ENTER to play <br />
                    ARROW UP for HELP <br />
                    ARROW DOWN to SKIP <br />
                    SHIFT ENTER to BLINK
                    <br />
                    CTR ENTER / CMD RETURN to TRANSLATE
                  </HoverSection>
                )}
              </button>
              <a
                href="https://thetime.pl/"
                data-tip
                data-for="other-tooltip"
                className="circle-btn"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={alarmClockIcon}
                  alt="alarm Clock"
                  className="w-100 h-100"
                />
              </a>
              <a
                href={`https://www.youtube.com/watch?v=${data?.videoId}` || ""}
                rel="noreferrer"
                target={"_blank"}
                className="circle-btn p-1"
                data-tip
                data-for="watch-on-youtube-tooltip"
              >
                <img
                  src={youtubeIcon}
                  alt="youtube Icon"
                  className="w-100 h-100"
                />
              </a>
              {!isLoggedIn ? (
                <Link
                  to="/login"
                  className="circle-btn p-0"
                  data-tip
                  data-for="login-tooltip"
                >
                  <img src={loginIcon} alt="Login" className="w-100 h-100" />
                </Link>
              ) : (
                <button
                  className="circle-btn p-0"
                  onClick={handleLogout}
                  data-tip
                  data-for="logout-tooltip"
                >
                  <img src={loginIcon} alt="logout" className="w-100 h-100" />
                </button>
              )}
              <Link
                to="/quotes/add"
                className="circle-btn p-1"
                data-tip
                data-for="add-quotes-tooltip"
              >
                <img src={plusIcon} alt="Plus" className="w-100 h-100" />
              </Link>
            </div>
          </section>
        </Container>
      </section>
      {showInfo && mobileScreen && (
        <HoverSection onClick={toggleInfoSection}>
          TYPE WHAT YOU HEAR IN THE BOX BELOW <br />
          PLAY AS MANY TIMES AS YOU NEED <br />
          TRAIN YOUR HEARING AND FOCUS
        </HoverSection>
      )}
      <>
        <ReactTooltip id="close-tooltip" place="top" effect="solid">
          {`Close Screen`}
        </ReactTooltip>
        <ReactTooltip id="f-screen-tooltip" place="top" effect="solid">
          {`Full Screen`}
        </ReactTooltip>
        <ReactTooltip id="info-tooltip" place="top" effect="solid">
          {`Info Text`}
        </ReactTooltip>
        <ReactTooltip id="other-tooltip" place="top" effect="solid">
          {`Check our other projects!`}
        </ReactTooltip>

        <ReactTooltip id="login-tooltip" place="top" effect="solid">
          {`Login`}
        </ReactTooltip>
        <ReactTooltip id="logout-tooltip" place="top" effect="solid">
          {`Logout`}
        </ReactTooltip>
        <ReactTooltip id="watch-on-youtube-tooltip" place="top" effect="solid">
          {`Watch It on Youtube`}
        </ReactTooltip>

        <ReactTooltip id="add-quotes-tooltip" place="top" effect="solid">
          {`Add Your Highlight!`}
        </ReactTooltip>
        <ReactTooltip id="other-tooltip" place="top" effect="solid">
          {`Check our other projects!`}
        </ReactTooltip>
      </>
    </>
  );
};
