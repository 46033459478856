import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import alarmClockIcon from "assets/icons/alarm-clock.svg";
import helpIcon from "assets/icons/help.svg";
import loginIcon from "assets/icons/login.svg";
import plusIcon from "assets/icons/plus.svg";
import { useSelector } from "react-redux";
import { logoutAsyncThunk } from "redux/pagesSlices/authSlice";
import { useDispatch } from "react-redux";
import { AiFillHome } from "react-icons/ai";

export const IntroHeader = () => {
  const { isLoggedIn } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(
      logoutAsyncThunk({
        callBack: () => navigate("/"),
      })
    );
  };

  return (
    <>
      <header className="d-flex flex-wrap align-items-center gap-2 justify-content-between justify-content-sm-end">
        {/* <Link to="/" className="circle-btn p-0" data-tip data-for="go-to-home">
          <AiFillHome />
        </Link>
        <Link
          to="/quotes/add"
          className="circle-btn p-0"
          data-tip
          data-for="add-quotes-tooltip"
        >
          <img src={plusIcon} alt="Plus" className="w-100 h-100" />
        </Link>
        <Link
          to="/help"
          className="circle-btn p-0"
          data-tip
          data-for="help-tooltip"
        >
          <button className="circle-btn " data-tip data-for="help-tooltip">
            <img src={helpIcon} alt="Help" className="w-100 h-100" />
          </button>
        </Link> */}
        <a
          href="https://thetime.pl/"
          data-tip
          data-for="other-tooltip"
          className="circle-btn"
          target="_blank"
          rel="noreferrer"
        >
          <img src={alarmClockIcon} alt="alarm Clock" className="w-100 h-100" />
        </a>

        {!isLoggedIn ? (
          <Link
            to="/login"
            className="circle-btn p-0"
            data-tip
            data-for="login-tooltip"
          >
            <img src={loginIcon} alt="Login" className="w-100 h-100" />
          </Link>
        ) : (
          <button
            className="circle-btn p-0"
            onClick={handleLogout}
            data-tip
            data-for="logout-tooltip"
          >
            <img src={loginIcon} alt="logout" className="w-100 h-100" />
          </button>
        )}
      </header>
      <ReactTooltip id="login-tooltip" place="top" effect="solid">
        {`Login`}
      </ReactTooltip>
      <ReactTooltip id="logout-tooltip" place="top" effect="solid">
        {`Logout`}
      </ReactTooltip>
      <ReactTooltip id="add-quotes-tooltip" place="top" effect="solid">
        {`Add Your Highlight!`}
      </ReactTooltip>
      <ReactTooltip id="go-to-home" place="top" effect="solid">
        {`Home Page!`}
      </ReactTooltip>
      <ReactTooltip id="help-tooltip" place="top" effect="solid">
        {`Help`}
      </ReactTooltip>
      <ReactTooltip id="other-tooltip" place="top" effect="solid">
        {`Check our other projects!`}
      </ReactTooltip>
    </>
  );
};
