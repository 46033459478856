import {
  useState,
  // useCallback,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, Button, Container } from "react-bootstrap";
import PasswordModal from "components/PasswordModal";
import useLocalStorageState from "hooks/useLocalStorageState";
import logo from "assets/logo.svg";

import { difficulties } from "./PrepareQuotes";
import { IntroHeader } from "components/IntroHeader";
import {
  useDispatch,
  useSelector,
  // useSelector
} from "react-redux";
import { getCollectionAsyncThunk } from "redux/pagesSlices/collectionSlice";
import { useAppSelector } from "redux/store";
import { CollectionNameSection } from "components/CollectionNameSection";
import VideoIntroModal from "components/VideoIntroModal";

// const isValidURL = (string) => {
//   try {
//     return new URL(string).pathname;
//   } catch (_) {
//     return string;
//   }
// };

export const IntroPage = () => {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showModal, setShowModal] = useLocalStorageState("password", true);
  const [search] = useSearchParams();
  console.log("showModal", showModal);

  const { isLoggedIn, profile } = useAppSelector((s) => s.auth);

  const { collections } = useAppSelector((s) => s.collection);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollectionAsyncThunk({}));
  }, [dispatch, collections?.data?.length]);

  const handleClose = () => {
    setShowPasswordModal(false);
  };
  // const isQuit = search.get("quit");
  // useEffect(() => {
  // const url = isValidURL(localStorage.getItem("progress"));
  // if (isQuit === "true") {
  // } else {
  //   navigate(url ?? "/quotes/1,2,3,4,5/Acting-Classes/play/?a=1");
  // }
  // }, [showModal, isQuit, navigate]);
  // const handleSubmitModal = () => {
  //   setShowPasswordModal(false);
  //   setShowModal(false);
  //   window.location.href = "/quotes/1,2,3,4,5/Acting-Classes/play/?a=1";
  //   // navigate("/quotes/1,2,3,4,5/Acting-Classes/play/?a=1");
  //   if (window.location.pathname !== "/") {
  //     window.location.reload();
  //   } else {
  //     handlePlay();
  //   }
  // };

  // const handleNavigation = useCallback(() => {
  //   if (showModal) setShowPasswordModal(true);
  //   else {
  //     navigate(`quotes/${selectedLevel}/${selectedCollection || "all"}/play/`);
  //   }
  //   // navigate(`quotes/${selectedLevel}/play`);
  // }, [navigate, selectedCollection, selectedLevel, showModal]);

  // const handlePlay = useCallback(handleNavigation, [handleNavigation]);

  // const handleLevel = (item) => {
  //   setSelectedLevel((prev) => {
  //     if (prev.includes(item)) {
  //       return prev.filter((i) => i !== item);
  //     } else {
  //       return [...prev, item];
  //     }
  //   });
  // };

  return (
    <>
      {/* <PasswordModal
        show={showPasswordModal}
        handleClose={handleClose}
        handleSubmitModal={handleSubmitModal}
      /> */}
      <section
        className="w-100 bg-bodyColor p-3 bg-tv"
        style={{ minHeight: "100vh" }}
      >
        <div
        // onClick={handleNavigation}
        >
          <IntroHeader />
          <div className="py-5">
            <Container className="">
              <div className="d-flex flex-wrap flex-column justify-content-between align-items-center h-100 gap-4">
                <div>
                  <img
                    src={logo}
                    alt="logo"
                    className="img-fluid max-h-200px"
                  />
                </div>
                <h3 className="text-black fw-bold text-capitalize">
                  {isLoggedIn ? profile.userName : "Guest"}
                </h3>

                <VideoIntroModal />
                {
                  // !showModal2 &&
                  <section className="col-12 col-md-8 col-lg-6">
                    {collections.isLoading ? (
                      <p className="text-center m-0">Loading...</p>
                    ) : collections.data.length > 0 ? (
                      <div className="d-flex flex-column gap-4 mb-4">
                        <Accordion>
                          {collections.data.map((item, i) => (
                            <CollectionNameSection
                              item={item}
                              key={i}
                              // showModal={showModal}
                              // setShowPasswordModal={setShowPasswordModal}
                            />
                            // <Accordion.Item eventKey={i} key={i}>
                            //   <Accordion.Header>
                            //     <h3 className="text-uppercase m-0 heading-3 fw-bold text-wrap text-break w-100 text-center">
                            //       {item.name}
                            //     </h3>
                            //   </Accordion.Header>
                            //   <Accordion.Body className="text-center">
                            //     <p className="text-uppercase paragraph text-center col-lg-6 mx-auto">
                            //       WE CAN PROVE IT AND HELP YOU IMPROVE IT!
                            //     </p>
                            //     <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center mb-4">
                            //       {difficulties.map((item, i) => (
                            //         <div
                            //           key={i}
                            //           className={`circle-btn circle-btn-large pe-cursor border border-dark ${
                            //             selectedLevel.includes(item)
                            //               ? "active"
                            //               : ""
                            //           }`}
                            //           onClick={() => handleLevel(item)}
                            //         >
                            //           {item}
                            //         </div>
                            //       ))}
                            //     </div>

                            //     <button
                            //       className="btn btn-dark bg-black rounded-pill text-uppercase text-bgColor heading-3 px-5"
                            //       onClick={handlePlay}
                            //       disabled={
                            //         selectedCollection === undefined ||
                            //         selectedLevel.length === 0
                            //       }
                            //     >
                            //       GO
                            //     </button>

                            //   </Accordion.Body>
                            // </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    ) : (
                      <p className="text-center m-0">No Collection Found</p>
                    )}
                  </section>
                }
                {/* {showModal2 && (
                  <Button
                    style={{
                      backgroundColor: "var(--text-color)",
                      color: "var(--bg-color)",
                    }}
                    onClick={() => {
                      setShowPasswordModal(true);
                    }}
                  >
                    Login
                  </Button>
                )} */}
              </div>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};
