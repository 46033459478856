import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import logo from "assets/logo.svg";

import { IntroHeader } from "components/IntroHeader";
import { useDispatch } from "react-redux";
import { setYoutubeUrlToAdd } from "redux/pagesSlices/quoteSlice";
import customToast from "hooks/customToast";
import { convertToTimeFormat } from "./PrepareQuotes";
import { clearQuoteValues } from "redux/pagesSlices/youtubeSlice";
import { convertSecsToTime } from "helpers/convert-time-to-secs";

export const AddHighlight = () => {
  // manage routes
  const navigate = useNavigate();

  // manage store
  const dispatch = useDispatch();

  // manage states
  const [url, setUrl] = useState("");
  const [startTime, setStartTime] = useState("");

  // methods
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!url) {
      customToast.error("Please put youtube url");
      return;
    }
    console.log(url);
    dispatch(setYoutubeUrlToAdd(url));
    navigate("/quotes/prepare");
  };
  // Function to extract start time from YouTube URL
  const extractStartTimeFromUrl = (url) => {
    const urlObject = new URL(url);
    const startTimeParam = urlObject.searchParams.get("t");
    return startTimeParam;
  };
  useEffect(() => {
    dispatch(clearQuoteValues());
  }, [dispatch]);
  // presentations
  return (
    <>
      <section
        className="w-100 bg-bodyColor p-3 bg-tv"
        style={{ minHeight: "100vh" }}
      >
        <IntroHeader />
        <Container className="add-hightlight">
          <div className="d-flex flex-column justify-content-center align-items-center h-100 gap-4">
            <div>
              <img src={logo} alt="logo" className="img-fluid max-h-200px" />
            </div>
            {/* <section className="text-center"> */}
            {/* <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center mb-4">
                  </div> */}
            <h2 className="title">ADD YOUR HIGHLIGHT</h2>
            <Link to={"/help"} className="instructions">
              WATCH INSTRUCTION
            </Link>

            <div className="input-controll">
              <label>1: Paste the youtube link:</label>
              <input
                value={url}
                required
                onChange={(e) => {
                  setUrl(e.target.value);
                  // Extract start time from YouTube URL if present
                  const startTimeParam = extractStartTimeFromUrl(
                    e.target.value
                  );
                  if (startTimeParam) {
                    setStartTime(convertSecsToTime(+startTimeParam).split(",").join("."));
                    // setStartTime(convertToTimeFormat(startTimeParam));
                  }
                }}
                type={"url"}
                placeholder="link"
              />
            </div>
            <div className="input-controll">
              <label>2: Put the start time:</label>
              <input
                value={startTime}
                required
                type="text"
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
                placeholder="00:00:00:00"
              />
            </div>

            <button
              className="btn btn-dark bg-black w-100 mb-2 rounded-pill text-uppercase fw-bold text-bgColor heading-3"
              onClick={handleSubmit}
            >
              CONTINUE
            </button>
            <p className="text-uppercase fs-12px m-0 ">
              If we like your HIGHLIGHT we will add it to a public
              collection! But it can take some time.
            </p>
            {/* </section> */}
          </div>
        </Container>
      </section>
    </>
  );
};
