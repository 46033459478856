import EditorFooter from "components/prepareControls/EditorFooter";
import PrepareQuotesHeader from "components/quotes/prepareQuotesHeader";
import { db } from "config/firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { convertSecsToTime } from "helpers/convert-time-to-secs";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import YouTube from "react-youtube";
import {
  clearQuoteValues,
  setInitiateQuoteValues,
  setQuoteValues,
} from "redux/pagesSlices/youtubeSlice";
import { useAppSelector } from "redux/store";

export const convertToSeconds = (time) => {
  if (typeof time === "string") {
    const [hours, minutes, seconds] = time.split(":");
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
  } else {
    return 0;
  }
};
export const convertToSecondsFloat = (time) => {
  if (typeof time === "number") {
    time = convertSecsToTime(time);
  }
  const [hours="00", minutes="00", seconds="00"] = time?.split(":") ?? ["00", "00", "00"];
  const t =
    parseInt(hours) * 3600 +
    parseInt(minutes) * 60 +
    parseFloat(seconds.split(",").join("."));
  console.log(`${time} => ${t}`);
  return t;
};
export const convertToTimeFormat = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};
export const extractVideoInfo = (url, navigate) => {
  if (!url) {
    navigate("/quotes/add");
    return { videoId: "", _startTime: "00:00:00", _endTime: "00:00:00" };
  }
  let videoId = "";
  let _startTime = "00:00:00";
  let _endTime = "00:00:00";
  const videoIdPattern =
    /(?:youtube\.com\/(?:watch\?.*v=|embed\/)|youtu\.be\/)([\w-]{11})/;

  // First, let's extract the video ID
  const videoIdMatches = url.match(videoIdPattern);
  if (videoIdMatches && videoIdMatches[1]) {
    videoId = videoIdMatches[1] ?? "";
  }

  // Now let's extract the start time, if there is one
  const startTimeMatches = url.match(/[?&]t=([^&]+)/);
  if (startTimeMatches && startTimeMatches[1]) {
    _startTime = convertToTimeFormat(startTimeMatches[1].split("s")[0]);
  }

  // Finally, let's extract the end time, if there is one
  const endTimeMatches = url.match(/[?&]end=([^&]+)/);
  if (endTimeMatches && endTimeMatches[1]) {
    _endTime = convertToTimeFormat(endTimeMatches[1] ?? 3);
  } else {
    _endTime = convertToTimeFormat(
      +(startTimeMatches?.[1]?.split("s")?.[0] ?? 0) + 3
    );
  }
  return {
    videoId,
    _startTime,
    _endTime,
  };
};

export const messages = [
  "Find your highlight, you can add some context before and after but DON’T include other TEXT.",
  "Type the highlight very carefully with full punctuation.",
  "How hard is it according to you?",
  "Which words best describe this highlight?",
];
export const difficulties = [1, 2, 3, 4, 5];
export const TAGS = [
  "cartoons",
  "youtube shows",
  "games",
  "TV",
  "talks",
  "sports",
  "music",
  "tutorials",
  "comedy",
  // "cartoon",
  // "casual",
  // "formal",
  // "business",
  // "fun",
  // "fast",
  // "accents",
  // "eloquence",
  // "music",
  // "sport",
  // "games",
  // "TV",
  // "cinema",
  // "podcast",
  // "smart",
  // "science",
  // "tech",
];
export const sTAGS = [
  "long",
  "fast",
  "accent",
  "slang",
  "spelling",
  "grammar",
  // "LONG",
  // "FAST",
  // "Accent",
];

const PrepareQuotes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { url } = useSelector((s) => s.quote);
  const { videoId, _startTime, _endTime } = extractVideoInfo(url, navigate);
  const playerRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [playerState, setPlayerState] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { startTime, endTime } = useAppSelector((s) => s.youtube.quoteData);

  useEffect(() => {
    if (!url) {
      navigate("/quotes/add");
    } else {
      dispatch(
        setInitiateQuoteValues({
          endTime: convertToSecondsFloat(_endTime),
          startTime: convertToSecondsFloat(_startTime),
          videoId,
          level: 1,
        })
      );
    }
    return () => {
      dispatch(clearQuoteValues());
    };
  }, [_endTime, _startTime, dispatch, navigate, url, videoId]);

  useEffect(() => {
    if (playerState === window?.YT?.PlayerState?.PLAYING) {
      const startSeconds = convertToSecondsFloat(startTime);
      const endSeconds = convertToSecondsFloat(endTime);
      const duration = endSeconds - startSeconds;
      const timeoutId = setTimeout(() => {
        playerRef.current.internalPlayer.pauseVideo();
        setIsPlaying(false);
      }, duration * 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [playerState, startTime, endTime]);
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      start: convertToSeconds(startTime),
      controls: 0,
      fs: 1,
      showRelatedVideos: false,
    },
  };

  const onReady = (event) => {
    setPlayer(event.target);
    setVideoDuration(event.target.getDuration());
    dispatch(
      setQuoteValues({
        time: "videoDuration",
        value: event.target.getDuration(),
      })
    );
    event.target.playVideo();
  };

  const handlePlayClick = () => {
    if (player) {
      player.seekTo(convertToSecondsFloat(startTime));
      player.playVideo();
    }
  };
  const handleStateChange = (event) => {
    setPlayerState(event.data);
  };
  return (
    <div className="prepare-quotes w-100 h-100 d-flex justify-content-center align-items-center">
      <PrepareQuotesHeader />
      <div
        style={{ position: "relative", flex: 1, width: "100%", height: "100%" }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {/* <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: playerState === window?.YT?.PlayerState?.PAUSED ? 'black' : 'transparent',
          }}
        // onClick={preventClick}
        /> */}
        <YouTube
          ref={playerRef}
          className="react-player"
          videoId={videoId}
          opts={opts}
          onReady={onReady}
          onStateChange={handleStateChange}
        />
      </div>
      <div className="prepare-quotes-control-container">
        <EditorFooter
          // quoteData={{
          //   startTime,
          //   endTime,
          //   videoId,
          //   difficulty,
          //   quotes,
          //   tags,
          //   sTags,
          // }}
          startAgain={handlePlayClick}
        />
      </div>
    </div>
  );
};

export default PrepareQuotes;
