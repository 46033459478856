import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import logo from "assets/logo.svg";
import { useAppSelector } from "redux/store";
import {
  Add,
  ExclamationIcon,
  HelpIcon,
  LetterB,
  LetterF,
  LetterH,
  LetterT,
  LetterW,
  PlayIcon,
} from "utils/icons";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import EditorFooter from "components/editor/EditorFooter";
import {
  setQoute,
  setQuoteData,
  setQuoteValues,
} from "redux/pagesSlices/youtubeSlice";
import { convertTimeToSecs } from "helpers/convert-time-to-secs";
import { Button } from "@mui/material";
import { updateQuoteAsyncThunk } from "redux/pagesSlices/quoteSlice";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
let isKeydown = false;
export const END_STATES = ["answer", "result", "finish", "skipped"];

/**
 *
 * @param {string} data
 * @returns {string}
 */
const parseSentance = (data) => {
  var punctRE =
    /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,.\-.\/:;<=>?@\[\]^_`{|}~]/g;
  var spaceRE = /\s+/g;
  return data
    .trim()
    .replaceAll("‘", "'")
    .replaceAll("’", "'")
    .replaceAll("‛", "'")
    .replaceAll("`", "'")
    .replaceAll("“", '"')
    .replaceAll("”", '"')
    .replaceAll("‟", '"')
    .replaceAll("„", '"')
    .toLowerCase()
    .replace(punctRE, "")
    .replace(spaceRE, " ");
};

// States
// 1. isPlaying
// 2. Input Text
// 3. Black Screen

// END States
// 1. Correct Answer
// 2. Skipped Answer
// 3. Round Completed
// 4. End of the video

export const If = ({ condition, children }) => {
  return condition ? children : null;
};

const onKeyUp = () => {
  isKeydown = false;
};
/**
 *
 * @param {{startAgain: ()=> void}} props
 * @returns {JSX.Element}
 */
export default function QuotesControl({
  startAgain,
  isPlaying,
  playedCounts,
  setPlayedCounts,
  data,
  helpCount,
  setHelpCount,
}) {
  const helpWordLetters = () => {
    if (data?.quotes?.length > helpCount) setHelpCount(helpCount + 1);
    // setScreen("answer")
    // setTimeout(() => {
    //   setScreen("input")
    // }, 1000);
  };

  const blink = () => {
    setScreen("blink");
    setTimeout(() => {
      setScreen("input");
    }, 1000);
  };

  const completeRound = () => {
    setPlayedCounts(playedCounts + 1);
  };

  const previusSentence = () => {
    setPlayedCounts(playedCounts + 1);
  };
  const { profile, isLoggedIn } = useSelector((s) => s.auth);
  const [showAdminControls, setShowAdminControls] = useState(false);
  const dispatch = useDispatch();
  const [searchq, setUrlSearch] = useSearchParams();
  const navigate = useNavigate();
  const a = searchq.get("a");
  const [screen, setScreen] = useState("input");
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const nextQuote = () => {
    //  screen input has been changed to answer and if admin is logged in then show the admin controls
    if (screen === "input") {
      setScreen("answer");
      if (profile.role === "admin" && isLoggedIn) setShowAdminControls(true);
    }

    // if (screen === "input") setScreen("answer");
    else {
      setHelpCount(0);
      setPlayedCounts(0);
      setUrlSearch({ a: ((+a ?? 0) + 1).toString() });
      setScreen("input");
    }
  };
  const previousQuote = () => {
    if (+a > 1) setUrlSearch({ a: (+a - 1).toString() });
  };

  /**
   *
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */

  useEffect(() => {
    if (screen !== "player") {
      console.log("Player Data", data);
      dispatch(setQoute({ ...data }));
    }
  }, [data, dispatch, screen]);
  const handleInput = (e) => {
    console.log("ans", data?.quotes);
    const answer = parseSentance(e.target.value).replace(/  +/g, " ");
    const currentSubtitle = parseSentance(data?.quotes ?? "");
    console.log("answer:", answer);
    console.log("currentSubtitle:", currentSubtitle);
    if (answer === currentSubtitle) {
      nextQuote();
      dispatch(
        updateQuoteAsyncThunk({
          id: data?.id,
          videoCompleteCountIncrement: true,
        })
      );
      // setScreen("result")
      // e.target.value = "";
      // setTimeout(() => {
      //   setScreen("input")
      //   setUrlSearch({ a: +a + 1 })
      // }, 1000);
    }
  };
  var checkKeyPress = useCallback(
    /**
     * @param {KeyboardEvent | React.KeyboardEvent<HTMLInputElement>} e
     */
    (e) => {
      const { keyCode } = e;
      console.log(keyCode, e.shiftKey, isKeydown);
      if (isKeydown) return e.preventDefault();
      // Space
      if (keyCode === 32 && screen == "answer") {
        console.log(screen);
        nextQuote();
        return e.preventDefault();
      }
      if (END_STATES.includes(screen) && keyCode === 32) {
        isKeydown = true;

        return e.preventDefault();
      }
      // Shift + LeftArrow
      if (keyCode === 37 && e.shiftKey) {
        isKeydown = true;
        previusSentence();
        return e.preventDefault();
      }
      // Shift + RightArrow
      else if (keyCode === 39 && e.shiftKey) {
        console.log("Shift + RightArrow");
        isKeydown = true;

        return e.preventDefault();
      } else if (keyCode === 38) {
        if (isKeydown) return e.preventDefault();
        isKeydown = true;
        helpWordLetters();
        return e.preventDefault();
      } else if (keyCode === 40) {
        isKeydown = true;
        nextQuote();
        setScreen("skipped");
        return e.preventDefault();
      } else if (keyCode === 13 && e.shiftKey) {
        completeRound();
        isKeydown = true;
        return e.preventDefault();
      } else if (keyCode === 13) {
        isKeydown = true;
        startAgain();
        return e.preventDefault();
        // Space
      }

      // screen === "result" + RightArrow
      if (screen === "result" && keyCode === 39) {
        isKeydown = true;

        return e.preventDefault();
      }
    },
    [helpCount, screen, startAgain]
  );
  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [checkKeyPress, helpCount, screen]);

  function fullscreenchanged(event) {
    if (document.fullscreenElement) {
      setIsFullScreenMode(true);
    } else {
      setIsFullScreenMode(false);
    }
  }
  useEffect(() => {
    document.addEventListener("fullscreenchange", fullscreenchanged);
  }, []);

  function maskText(text = "") {
    return text
      ?.split(/\s/)
      .map(
        (word) =>
          word.slice(0, helpCount) +
          word.slice(helpCount, word.length).replace(/[a-zA-Z0-9 ]/g, "_")
      )
      .join(" ");
  }
  function hideHelpText(className = "show-text-click") {
    document
      .querySelector(".showDescription")
      ?.classList?.remove(className ?? "show-text");
  }

  function showHelpText(className = "show-text-click") {
    document
      .querySelector(".showDescription")
      ?.classList?.add(className ?? "show-text");
  }
  function onShowHelpTextTuggle() {
    showHelpText("show-text-click");
    setTimeout(() => {
      hideHelpText("show-text-click");
    }, 2000);
  }

  useEffect(() => {
    console.log("a", a);
    console.log("Data Id", data);
    if (data) {
      dispatch(
        updateQuoteAsyncThunk({
          id: data?.id,
          videoPlayCountIncrement: true,
        })
      );
    }
  }, [a, data, dispatch]);

  console.log("screen =====>", screen);
  return (
    <>
      <section
        className={`vw-100 vh-100 d-flex justify-content-center align-items-center position-absolute top-0 left-0 play-section ${
          !isPlaying
            ? screen !== "skipped" && screen !== "answer"
              ? "show"
              : ""
            : ""
        }`}
        style={{ zIndex: 5 }}
      >
        <img
          src={logo}
          alt="logo"
          className="img-fluid h-100 pe-cursor"
          onClick={startAgain}
          style={{ maxHeight: screenWidth > 575 ? "120px" : "60px" }}
        />
      </section>

      <If
        condition={
          (screen === "answer" || screen === "skipped") && screenWidth > 576
        }
      >
        <>
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: 0,
              margin: "auto",
              zIndex: 100,
            }}
          >
            <Container>
              <p
                className="text-center heading-4 fw-bold border-text text-uppercase mb-4"
                style={{ color: "var(--bg-color)" }}
              >
                {screen === "skipped"
                  ? "Skipped"
                  : helpCount === 0 && playedCounts === 0
                  ? "Perfect"
                  : "Correct"}
              </p>
              <h2
                className="border-text text-border-2px text-uppercase"
                style={{
                  color: "var(--bg-color)",
                  textAlign: "center",
                  fontSize: `clamp(1.5rem, 5vw, 6rem)`,
                  fontWeight: "900",
                }}
              >
                {data?.quotes}
              </h2>
            </Container>
          </div>
        </>
      </If>
      {showAdminControls ? (
        <EditorFooter
          quoteData={data}
          startAgain={startAgain}
          saveQoute={nextQuote}
        />
      ) : screenWidth > 576 ? (
        <div className="quotes-controll-inputs-container">
          <div className="quotes-controll-inputs">
            <If condition={screen === "input"}>
              <div className="InputControl">
                {/* <button
              data-tip
              data-for="previus-quote"
              onClick={previousQuote}
              type="button"
              className="btn btn-primary btn-circle btn-sm"
              style={{
                color: END_STATES.includes(screen)
                  ? "var(--bg-color)"
                  : "var(--text-color)",
              }}
            >
              <FaAngleDoubleLeft size={32} />
            </button> */}
                <div
                  style={{
                    flex: 1,
                    paddingInline: "5px",
                    color: "#000",
                  }}
                >
                  <If condition={helpCount}>
                    <h5
                      className="heading-5 fw-normal help-text 
              position-absolute top-0 mt-3 text-center w-100 flex-wrap"
                    >
                      {maskText(data?.quotes, helpCount)}
                    </h5>
                  </If>
                  <input
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      checkKeyPress(e);
                    }}
                    onKeyUp={onKeyUp}
                    autoComplete="off"
                    autoFocus
                    id="ans-text"
                    placeholder="TYPE WHAT YOU HEAR"
                    className="input-answer heading-1"
                    onChange={handleInput}
                  />
                  {/* <div className="info mt-3">
              <button
                onClick={helpWordLetters}
                data-tip
                data-for="flash"
                type="button"
                className="btn btn-primary btn-circle btn-sm"
              >
                <div
                  className="squre fw-bold paragraph"
                  style={{ color: "var(--bg-color)" }}
                >
                  {helpCount}
                </div>
              </button>
              <button
                onClick={startAgain}
                data-tip
                data-for="play"
                type="button"
                className="btn btn-primary btn-circle btn-sm"
              >
                <div className="circle ">
                  <PlayIcon width="14" style={{ marginLeft: "2px" }} />
                </div>
              </button>
            </div> */}
                </div>
                {/* <button
              data-tip
              data-for="next-skip"
              onClick={nextQuote}
              type="button"
              className="btn btn-primary btn-circle btn-sm"
              style={{
                color: END_STATES.includes(screen)
                  ? "var(--bg-color)"
                  : "var(--text-color)",
              }}
            >
              <FaAngleDoubleRight size={32} />
            </button> */}
              </div>
            </If>
            <If condition={screen === "blink"}>
              <div
                className="InputControl flex-column"
                style={{ backgroundColor: "black" }}
              >
                <section className="d-flex justify-content-between w-100">
                  <div style={{ flex: 1 }}>
                    <h2
                      className="heading-2"
                      style={{ color: "var(--bg-color)", textAlign: "center" }}
                    >
                      {data?.quotes}
                    </h2>
                  </div>
                </section>
              </div>
            </If>

            <If condition={screen === "answer" || screen === "skipped"}>
              <div
                className="InputControl flex-column"
                style={{ backgroundColor: "black" }}
              >
                <div
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{
                    // backgroundColor: "rgba(0,0,0,0.5)",
                    height: "100vh",
                    position: "fixed",
                    top: "0",
                    left: 0,
                    margin: "auto",
                    // zIndex: -100,
                  }}
                >
                  {/*  <Container>
                    <p
                      className="text-center heading-4 fw-bold border-text text-uppercase mb-4"
                      style={{ color: "var(--bg-color)" }}
                    >
             

                      {screen === "skipped"
                        ? "Skipped"
                        : helpCount === 0 && playedCounts === 0
                        ? "Perfect"
                        : "Correct"}
                    </p>
                    <section> */}
                  {/* <button
              data-tip
              data-for="previus-quote"
              onClick={previousQuote}
              type="button"
              className="btn btn-primary btn-circle btn-sm"
              style={{
                color: END_STATES.includes(screen)
                  ? "var(--bg-color)"
                  : "var(--text-color)",
              }}
            >
              <FaAngleDoubleLeft size={32} />
            </button> */}
                  {/* <button
              data-tip
              data-for="prevous"
              onClick={previusSentence}
              className="btn"
              style={{ color: "var(--bg-color)" }}
            ></button> */}
                  {/* <div style={{ flex: 1 }}> */}
                  {/* <h2
                        className="border-text text-border-2px text-uppercase"
                        style={{
                          color: "var(--bg-color)",
                          textAlign: "center",
                          fontSize: `clamp(1.5rem, 5vw, 6rem)`,
                          fontWeight: "900",
                        }}
                      >
                        {data?.quotes}
                      </h2> */}
                  {/* </div> */}
                  {/* <button
              data-tip
              data-for="next-skip"
              onClick={nextQuote}
              type="button"
              className="btn btn-primary btn-circle btn-sm"
              style={{
                color: END_STATES.includes(screen)
                  ? "var(--bg-color)"
                  : "var(--text-color)",
              }}
            >
              <FaAngleDoubleRight size={32} />
            </button> */}
                  {/* </section>
                  </Container> */}
                </div>
              </div>
            </If>
            <If condition={screen === "result"}>
              <div
                className="InputControl"
                style={{ backgroundColor: "black" }}
              >
                <div style={{ flex: 1, paddingInline: "5px" }}>
                  <h1
                    className="heading-1"
                    style={{ color: "var(--bg-color)", textAlign: "center" }}
                  >
                    Highlight Done Go To Next.
                  </h1>
                </div>
              </div>
            </If>
            <If condition={screen === "finish"}>
              <div
                className="InputControl"
                style={{ backgroundColor: "black" }}
              >
                <div style={{ flex: 1, paddingInline: "5px" }}>
                  <h1
                    className="heading-1"
                    style={{ color: "var(--bg-color)" }}
                  >
                    The End! <br /> Revise it with{" "}
                    <a
                      href="https://donotblink.app/app"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration"
                      style={{ color: "var(--bg-color)" }}
                    >
                      do not blink
                    </a>
                    .
                  </h1>
                </div>
              </div>
            </If>
            {/*   <div
        className="position-absolute icons"
        style={{
          zIndex: 20,
        }}
      >
        <button
          data-tip
          data-for="f-screen"
          onClick={() => {
            // dispatch(switchFullScreen())
            if (isFullScreenMode) {
              document.exitFullscreen();
            } else {
              document.body.requestFullscreen();
            }
          }}
          type="button"
          className="btn btn-primary btn-circle btn-sm"
          style={{
            color: END_STATES.includes(screen)
              ? "var(--bg-color)"
              : "var(--text-color)",
          }}
        >
          <LetterF opacity={isFullScreenMode ? 1 : 0.7} />
        </button>

        <button
          data-tip
          data-for="help"
          type="button"
          className="btn btn-primary btn-circle btn-sm"
          style={{
            color: END_STATES.includes(screen)
              ? "var(--bg-color)"
              : "var(--text-color)",
          }}
        >
          <ExclamationIcon opacity={0.4} />
        </button>
        <button
          data-tip
          data-for="info"
          type="button"
          onClick={onShowHelpTextTuggle}
          onMouseOver={() => showHelpText()}
          onMouseOut={() => hideHelpText()}
          className="btn btn-primary btn-circle btn-sm "
          style={{
            color: END_STATES.includes(screen)
              ? "var(--bg-color)"
              : "var(--text-color)",
          }}
        >
          <HelpIcon opacity={0.4} />
        </button>
      </div>
      <div
        className="position-absolute icons-right"
        style={{
          zIndex: 20,
        }}
      >
        <button
          data-tip
          data-for="help"
          type="button"
          className="btn btn-primary btn-circle btn-sm"
          style={{
            color: END_STATES.includes(screen)
              ? "var(--bg-color)"
              : "var(--text-color)",
          }}
        >
          <LetterW opacity={isFullScreenMode ? 1 : 0.7} />
        </button>

        <button
          data-tip
          data-for="help"
          type="button"
          className="btn btn-primary btn-circle btn-sm"
          style={{
            color: END_STATES.includes(screen)
              ? "var(--bg-color)"
              : "var(--text-color)",
          }}
        >
          <LetterT opacity={isFullScreenMode ? 1 : 0.7} />
        </button>
        <button
          data-tip
          data-for="blink"
          onClick={blink}
          type="button"
          className="btn btn-primary btn-circle btn-sm"
          style={{
            color: END_STATES.includes(screen)
              ? "var(--bg-color)"
              : "var(--text-color)",
          }}
        >
          <LetterB opacity={0.7} />
        </button>
        <button
          data-tip
          data-for="add-quotes"
          type="button"
          onClick={() => navigate("/quotes/add")}
          className="btn btn-primary btn-circle btn-sm "
          style={{
            justifyContent: "center",
            alignItems: "center",
            color: END_STATES.includes(screen)
              ? "var(--bg-color)"
              : "var(--text-color)",
            backgroundColor: "orange !important",
          }}
        >
          <Add opacity={0.7} />
        </button>
      </div> */}

            {/* <ReactTooltip id="previus-quote" place="top" effect="solid">
        {`Previous`}
      </ReactTooltip>
      <ReactTooltip id="next-quote" place="top" effect="solid">
        {`Next Highlight [Arrow Down]`}
      </ReactTooltip>
      <ReactTooltip id="next-skip" place="top" effect="solid">
        {`Next [SPACE] or [ARROW DOWN]`}
      </ReactTooltip>
      <ReactTooltip id="translate" place="top" effect="solid">
        {`Translate`}
      </ReactTooltip>
      <ReactTooltip id="help-text" place="top" effect="solid">
        {`Help`}
      </ReactTooltip>
      <ReactTooltip id="f-screen" place="top" effect="solid">
        {`Full Screen`}
      </ReactTooltip>
      <ReactTooltip id="help" place="top" effect="solid">
        {`Comming Soon`}
      </ReactTooltip>
      <ReactTooltip id="blink" place="top" effect="solid">
        {`blink`}
      </ReactTooltip>
      <ReactTooltip id="info" place="top" effect="solid">
        {`info`}
      </ReactTooltip>
      <ReactTooltip id="add-quotes" place="top" effect="solid">
        {`Add Your Highlight!`}
      </ReactTooltip>
      <ReactTooltip id="flash" place="top" effect="solid">
        Help: <kbd>Arrow Up</kbd>
      </ReactTooltip>
      <ReactTooltip id="play" place="top" effect="solid">
        Play: <kbd>Enter</kbd>
      </ReactTooltip> */}

            {/* Help tooltip, next tooltip, skip tooltip, blink tooltip , trans tooltip */}
            <ReactTooltip id="help-tooltip" place="top" effect="solid">
              Help: <kbd>Arrow Up</kbd>
            </ReactTooltip>
            <ReactTooltip id="play-tooltip" place="top" effect="solid">
              Play: <kbd>Enter</kbd>
            </ReactTooltip>

            <ReactTooltip id="skip-tooltip" place="top" effect="solid">
              Skip: <kbd>Arrow down</kbd>
            </ReactTooltip>

            <ReactTooltip id="next-tooltip" place="top" effect="solid">
              Next: <kbd>Arrow down</kbd>
            </ReactTooltip>

            <ReactTooltip id="blink-tooltip" place="top" effect="solid">
              {`blink`}
            </ReactTooltip>
            <ReactTooltip id="translate-tooltip" place="top" effect="solid">
              {`Translation`}
            </ReactTooltip>

            <section
              className={`d-flex justify-content-between align-items-center gap-4 w-100 position-absolute bottom-0 controls-button ${screen}`}
            >
              <Button
                className={`${
                  screen === "answer" ? "text-bgColor" : "text-dark"
                } fw-bold text-uppercase`}
                onClick={helpWordLetters}
                data-tip
                data-for="help-tooltip"
                disabled={screen !== "input"}
              >
                help
              </Button>
              <Button
                className={`${
                  screen === "answer" ? "text-bgColor" : "text-dark"
                } fw-bold text-uppercase`}
                onClick={blink}
                data-tip
                data-for="blink-tooltip"
                disabled={screen !== "input"}
              >
                blink
              </Button>
              <Button
                className={`${
                  screen === "answer" || screen === "skipped"
                    ? "text-bgColor"
                    : "text-dark"
                } fw-bold text-uppercase`}
                style={{ fontSize: "20px" }}
                onClick={startAgain}
                data-tip
                data-for="play-tooltip"
              >
                play
              </Button>
              <Button
                className={`${
                  screen === "answer" ? "text-bgColor" : "text-dark"
                } fw-bold text-uppercase`}
                data-tip
                data-for="translate-tooltip"
              >
                <a
                  href={`http://translate.google.com?text=${data?.quotes}`}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-decoration-none ${
                    screen === "answer" ? "text-bgColor" : "text-dark"
                  }`}
                >
                  trans.
                </a>
              </Button>
              {screen === "input" ? (
                <Button
                  className={`text-dark fw-bold text-uppercase`}
                  onClick={() => {
                    nextQuote();
                    setScreen("skipped");
                  }}
                  data-tip
                  data-for="skip-tooltip"
                >
                  skip
                </Button>
              ) : (
                <Button
                  className={`text-bgColor fw-bold text-uppercase`}
                  onClick={() => {
                    nextQuote();
                    setScreen("skipped");
                  }}
                  data-tip
                  data-for="next-tooltip"
                >
                  next
                </Button>
              )}
            </section>
          </div>
        </div>
      ) : (
        <div className="p-2 d-flex flex-column gap-3 action-section overflow-auto">
          <If condition={screen === "result"}>
            <div
              className="input-button d-flex flex-column justify-content-center align-items-center gap-2"
              style={{
                minHeight: "150px",
              }}
            >
              Highlight Done Go To Next.
            </div>
          </If>
          <If condition={screen === "finish"}>
            <div
              className="input-button d-flex flex-column justify-content-center align-items-center gap-2"
              style={{
                minHeight: "150px",
              }}
            >
              The End! <br /> Revise it with{" "}
              <a
                href="https://donotblink.app/app"
                target="_blank"
                rel="noreferrer"
                className="text-decoration"
                style={{ color: "var(--bg-color)" }}
              >
                do not blink.
              </a>
            </div>
          </If>
          <If condition={screen === "answer" || screen === "skipped"}>
            <div
              className="input-button d-flex flex-column justify-content-center align-items-center gap-2"
              style={{
                minHeight: "150px",
              }}
            >
              <p className="m-0 fs-12px">
                {screen === "skipped"
                  ? "Skipped"
                  : helpCount === 0
                  ? "Perfect"
                  : "Correct"}
              </p>
              {data?.quotes}
            </div>
            <Button
              className="input-button w-100"
              onClick={() => {
                nextQuote();
                setScreen("skipped");
              }}
              data-tip
              data-for="next-tooltip"
            >
              next
            </Button>
          </If>

          <If condition={screen === "input"}>
            <div>
              <input
                onKeyDown={(e) => {
                  e.stopPropagation();
                  checkKeyPress(e);
                }}
                onKeyUp={onKeyUp}
                autoComplete="off"
                id="ans-text"
                placeholder="TYPE WHAT YOU HEARD"
                className="form-control input-field-text"
                onChange={handleInput}
              />
            </div>
          </If>
          <If condition={screen === "blink"}>
            <div
              className="input-button d-flex justify-content-center align-items-center"
              style={{ minHeight: "150px" }}
            >
              {/* Blink */}
              {data?.quotes}
            </div>
          </If>
          <If condition={screen !== "answer" && screen !== "skipped"}>
            <div>
              <div className="d-flex gap-3">
                <Button
                  className="input-button w-100 active"
                  onClick={blink}
                  data-tip
                  data-for="blink-tooltip"
                  disabled={screen !== "input"}
                >
                  blink
                </Button>
                <Button
                  className="input-button w-100 active"
                  onClick={() => {
                    nextQuote();
                    setScreen("skipped");
                  }}
                  data-tip
                  data-for="skip-tooltip"
                >
                  Check
                </Button>
              </div>

              <p className="text-center text-bgColor text-capitalize fs-12px mb-0 mt-1">
                SAY IT OR WRITE IT DOWN FIRST!
              </p>
            </div>
          </If>
          <div className="fw-bold text-uppercase text-center text-bgColor py-2 heading-5">
            FULL VERSION ON DESKTOP
          </div>
        </div>
      )}
    </>
  );
}
