import React from "react";

export const ArrowLeftIcon = (props) => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.75141 10.8924L22.146 3.03398C22.5423 2.81812 22.7604 2.52951 22.7604 2.22177C22.7604 1.91387 22.5423 1.62543 22.146 1.40922L20.8846 0.720999C20.4889 0.504455 19.9599 0.385254 19.3962 0.385254C18.8325 0.385254 18.3041 0.504455 17.9081 0.720999L0.769054 10.0771C0.371447 10.294 0.153718 10.5838 0.155282 10.8919C0.153718 11.2013 0.371134 11.4908 0.769054 11.7078L17.8921 21.0549C18.2881 21.2714 18.8165 21.3906 19.3805 21.3906C19.9443 21.3906 20.4726 21.2714 20.869 21.0549L22.13 20.3667C22.9506 19.9187 22.9506 19.1895 22.13 18.7417L7.75141 10.8924Z"
        fill="currentColor"
      />
      <path
        d="M11.458 10.513L29.1824 3.04163L29.1824 17.9844L11.458 10.513Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowRightIcon = (props) => {
  return (
    <svg
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.6212 9.88397L7.88096 17.1811C7.50262 17.3815 7.29449 17.6495 7.29449 17.9353C7.29449 18.2212 7.50262 18.489 7.88096 18.6898L9.08496 19.3288C9.4627 19.5299 9.96765 19.6406 10.5057 19.6406C11.0438 19.6406 11.5482 19.5299 11.9262 19.3288L28.2862 10.641C28.6657 10.4396 28.8736 10.1705 28.8721 9.88445C28.8736 9.59711 28.666 9.32832 28.2862 9.12677L11.9415 0.44736C11.5634 0.246284 11.0591 0.135595 10.5207 0.135594C9.98258 0.135594 9.47823 0.246284 9.09989 0.44736L7.8962 1.08642C7.11294 1.50237 7.11294 2.1795 7.8962 2.59529L21.6212 9.88397Z"
        fill="currentColor"
      />
      <path
        d="M18.5967 9.51283L0.872245 16.9842L0.872246 2.04145L18.5967 9.51283Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const PlayIcon = (props) => {
  return (
    <svg
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.5578 9.87645C23.1471 11.4301 23.1471 15.1828 20.5578 16.7364L6.4913 25.1763C3.8252 26.7759 0.433311 24.8555 0.433311 21.7463L0.433311 4.86654C0.433311 1.75736 3.8252 -0.163097 6.49129 1.43656L20.5578 9.87645Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LetterF = (props) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6371 0.97522V4.10972H4.83076V7.49589H9.92362V10.5389H4.83076V17.0367H0.76123V0.97522H11.6371Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const LetterW = (props) => {
  return (
    <svg
      width="40"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path opacity="0.5" d="M23.5488 0.853999L19.3398 17H14.5788L12.0028 6.374L9.33479 17H4.57379L0.479785 0.853999H4.68879L7.01179 12.607L9.88679 0.853999H14.2108L16.9708 12.607L19.3168 0.853999H23.5488Z" fill="black" />
    </svg>
  );
};
export const LetterT = (props) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path opacity="0.5" d="M13.2466 0.853999V4.005H8.96857V17H5.03557V4.005H0.757566V0.853999H13.2466Z" fill="black" />

    </svg>
  );
};
export const LetterB = (props) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path opacity="0.5"
        d="M148.004,94.812c18.332-8.126,28.671-23.362,28.671-42.752c0-17.261-6.954-31.206-20.11-40.328
        C145.653,4.166,130.438,0,113.721,0H16.957v34h17v134h-17v34h90.905c14.819,0,35.992-2.245,52.705-12.94
        c16.241-10.393,24.476-26.161,24.476-46.868C185.043,118.342,171.057,100.763,148.004,94.812z M103.12,80H73.957V34h26.096
        c25.961,0,36.551,6.34,36.551,21.884C136.604,75.816,118.396,80,103.12,80z M73.957,115h30.838c28.537,0,40.177,7.436,40.177,25.663
        c0,18.14-13.987,27.337-41.572,27.337H73.957V115z"
        fill="black"
      />
    </svg>
  );
};



export const LetterH = (props) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.5"
        d="M0.757 0.854V17H3.69V11.355H8.308V17H11.241V0.854H8.308V6.498H3.69V0.854H0.757Z"
        fill="black"
      />
    </svg>
  );
};


export const Add = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
      opacity="0.5"
        d="M6 1V11M1 6H11"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ExclamationIcon = (props) => {
  return (
    <svg
      width="7"
      height="18"
      viewBox="0 0 7 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.71654 0.97522L6.11168 10.9531H1.69154L1.08668 0.97522H6.71654ZM3.90161 17.0367C3.00207 17.0367 2.28089 16.8191 1.73807 16.3841C1.19524 15.9491 0.923828 15.4018 0.923828 14.7422C0.923828 14.0686 1.19524 13.5142 1.73807 13.0792C2.28089 12.6301 3.00207 12.4056 3.90161 12.4056C4.78564 12.4056 5.49907 12.6301 6.04189 13.0792C6.58471 13.5142 6.85613 14.0686 6.85613 14.7422C6.85613 15.4018 6.58471 15.9491 6.04189 16.3841C5.49907 16.8191 4.78564 17.0367 3.90161 17.0367Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const HelpIcon = (props) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.86061 0C6.30525 0 7.45962 0.398754 8.32371 1.19626C9.2013 1.99377 9.64009 3.11883 9.64009 4.57143C9.64009 5.9101 9.22155 6.95683 8.38446 7.71162C7.56088 8.45216 6.46727 8.82955 5.10363 8.84379L5.00237 10.4459H1.96457L1.86331 6.47263H3.07843C4.11803 6.47263 4.90786 6.33734 5.44792 6.06676C6.00147 5.79617 6.27825 5.30485 6.27825 4.59279C6.27825 4.09435 6.14999 3.70271 5.89346 3.41789C5.63694 3.13307 5.27915 2.99065 4.82011 2.99065C4.33406 2.99065 3.95602 3.14019 3.68599 3.43925C3.41596 3.72408 3.28095 4.11571 3.28095 4.61415H0.0203715C-0.00663121 3.74544 0.162136 2.96217 0.526673 2.26435C0.904711 1.56653 1.45827 1.01825 2.18734 0.619494C2.92991 0.206498 3.821 0 4.86061 0ZM3.52397 16C2.91641 16 2.41686 15.8149 2.02532 15.4446C1.64729 15.0601 1.45827 14.5901 1.45827 14.0347C1.45827 13.4651 1.64729 12.988 2.02532 12.6035C2.41686 12.219 2.91641 12.0267 3.52397 12.0267C4.11803 12.0267 4.60408 12.219 4.98212 12.6035C5.37366 12.988 5.56943 13.4651 5.56943 14.0347C5.56943 14.5901 5.37366 15.0601 4.98212 15.4446C4.60408 15.8149 4.11803 16 3.52397 16Z"
        fill="currentColor"
      />
    </svg>
  );
};
