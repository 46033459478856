import React from // , { useEffect }
"react";
import {
  // Navigate,
  Outlet,
  // , useLocation
} from "react-router-dom";
// import useLocalStorageState from "hooks/useLocalStorageState";

const Auth = () => {
  // const [showModal, setShowModal] = useLocalStorageState("password", true);
  // const location = useLocation();
  // const { pathname } = location;
  // if (showModal && pathname != "/app") {
  // return <Navigate to={"app"} />;
  // } else {
  return <Outlet />;
  // }
};

export default Auth;
