import { Container } from "react-bootstrap";

export const HoverSection = ({ children, onClick }) => {
  return (
    <div
      className="position-fixed top-0 start-0 w-100 vh-100 bg-black bg-opacity-75 d-flex justify-content-center align-items-center hover-section"
      onClick={onClick}
    >
      <Container>
        <p className="border-text fst-italic text-center paragraph fw-semibold">
          {children}
        </p>
      </Container>
    </div>
  );
};
