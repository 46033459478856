import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

export default function CirleInfo({ startAgain, startPlay }) {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [startPlay]);
  // mange end
  return (
    <div
      className={`position-absolute bg-opacity-50 h-100 w-100 bg-black d-flex justify-content-center align-items-center flex-wrap flex-column showDescription ${startPlay && "hidden"
        } pe-cursor`}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        zIndex: 1000000000000000000000000
      }}
      onClick={(e) => {
        e.stopPropagation();
        // startAgain();
      }}
    >
      <h1 className="text-border text-center text-bgColor heading-1">
        TYPE WHAT YOU HEAR IN THE BOX BELOW<br />
        PLAY AS MANY TIME AS YOU NEED<br />
        TRAIN YOUR HEARING AND YOUR MEMORY<br />
        ENTER to play<br />
        ARROW UP for HELP<br />
        ARROW DOWN to SKIP<br />
      </h1>
    </div>
  );
}
