import {
  // IconButton,
  Button,
} from "@mui/material";
import { convertSecsToTime } from "helpers/convert-time-to-secs";
import React, { useCallback, useEffect, useRef } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch } from "react-redux";
import {
  setEdit,
  setQoute,
  setQuoteValues,
} from "redux/pagesSlices/youtubeSlice";
import { useAppSelector } from "redux/store";
import { TAGS, difficulties, sTAGS } from "pages/PrepareQuotes";
import {
  deleteQuoteAsyncThunk,
  updateQuoteAsyncThunk,
} from "redux/pagesSlices/quoteSlice";
import { toast } from "react-toastify";
/**
 * @param {"startTime" | "endTime"} type
 * @returns {[number, React.Dispatch<React.SetStateAction<number>>]}
 */
// @ts-ignore
const useControlledState = (type) => {
  const time = useAppSelector((s) => s.youtube.quoteData[type]);

  const dispatch = useDispatch();
  const setTime = useCallback(
    (valueOrFunc) => {
      dispatch(
        setQuoteValues({
          time: type,
          value:
            typeof valueOrFunc === "function"
              ? valueOrFunc(time)
              : valueOrFunc ?? "00:00:00,00",
        })
      );
    },
    [dispatch, type, time]
  );

  return [time, setTime];
};

/**
 *
 * @param {{ time: "startTime" | "endTime" }} props
 * @returns {JSX.Element}
 */
const ButtonContainer = ({ time }) => {
  const [currTime, setCurrTime] = useControlledState(time);

  return (
    <section className="d-flex justify-content-center align-items-center gap-2">
      <button className="btn border-0 p-0 text-bgColor fw-bold">
        <KeyboardArrowLeftIcon
          onClick={() => {
            setCurrTime((t) => t - 0.1);
          }}
        />
      </button>
      <button className="btn border-0 p-0 text-bgColor fw-bold">
        <KeyboardArrowLeftIcon
          onClick={() => {
            setCurrTime((t) => t - 0.01);
          }}
        />
      </button>
      <h4 className="heading-4 fw-bold m-0">
        {convertSecsToTime(currTime).split(",").join(".")}
      </h4>
      <button className="btn border-0 p-0 text-bgColor fw-bold">
        <KeyboardArrowRightIcon
          onClick={() => {
            setCurrTime((t) => t + 0.01);
          }}
        />
      </button>
      <button className="btn border-0 p-0 text-bgColor fw-bold">
        <KeyboardArrowRightIcon
          onClick={() => {
            setCurrTime((t) => t + 0.1);
          }}
        />
      </button>
    </section>
  );
};

/**
 *
 * @param {{ startAgain: () => void }} props
 * @returns {JSX.Element}
 */
export default function EditorFooter({ quoteData, startAgain, saveQoute }) {
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  // const editState = useAppSelector((s) => s.youtube.editState);
  const ytQuoteData = useAppSelector((s) => s.youtube.quoteData);
  console.log("Get quoteData", quoteData);
  console.log("Get ytQuoteData", ytQuoteData);

  const handleSave = async () => {
    dispatch(
      updateQuoteAsyncThunk({
        id: quoteData.id,
        data: {
          ...quoteData,
          difficulty: ytQuoteData.level,
          quotes: ytQuoteData.quote,
          startTime: convertSecsToTime(ytQuoteData.startTime),
          endTime: convertSecsToTime(ytQuoteData.endTime),
          // tags: ytQuoteData.tags,
          sTags: ytQuoteData.sTags,
        },
        callBack: () => {
          toast.success("Quote updated successfully");
          saveQoute();
        },
      })
    );
  };

  const deleteQoute = async () => {
    dispatch(
      deleteQuoteAsyncThunk({
        id: quoteData.id,
        callBack: () => {
          toast.success("Quote deleted successfully");
          saveQoute();
        },
      })
    );
  };

  return (
    <section
      className="position-absolute bottom-0 left-0 w-100 p-2"
      style={{ zIndex: 10000 }}
    >
      <div className="bg-black bg-opacity-75 p-3 rounded-4 border border-bgColor  border-3 text-center text-bgColor">
        {/* Timer Section Start*/}
        <section className="d-flex justify-content-between justify-content-md-center align-items-center flex-wrap gap-3">
          <ButtonContainer time="startTime" />
          <div className="px-5 d-none d-md-block">
          </div>
          <ButtonContainer time="endTime" />
        </section>

        {/* Input Field Section */}

        {/* <If condition={editState === "input"}> */}
        <div className="">
          <input
            autoComplete="off"
            autoFocus
            id="ans-text"
            placeholder="TYPE WHAT YOU HEAR"
            className="heading-2 form-control-plaintext text-bgColor text-center"
            onChange={(e) => {
              dispatch(
                setQuoteValues({
                  time: "quote",
                  value: e.target.value,
                })
              );
            }}
            // value={quoteData.quote}
            value={ytQuoteData.quote}
            ref={inputRef}
          />
        </div>
        {/* </If> */}

        {/* Level Section Start */}
        {/* <If condition={editState === "level"}> */}
        <section className="d-flex align-items-center justify-content-center gap-2 mt-3 mb-4 flex-wrap">
          {difficulties.map((num, i) => (
            <button
              key={i}
              onClick={() => {
                dispatch(
                  setQuoteValues({
                    time: "level",
                    value: num,
                  })
                );
              }}
              className={`text-decoration-none circle-btn circle-btn-large ${
                num === ytQuoteData.level ? "" : "active"
              }`}
            >
              {num}
            </button>
          ))}
        </section>
        {/* </If> */}

        {/* Tags Section Start */}
        {/* <If condition={editState === "tags"}> */}
        {ytQuoteData?.sTags !== undefined && (
          <div className="tags">
            {sTAGS.map((el, i) => (
              <span
                key={i}
                style={{
                  fontWeight: ytQuoteData.sTags.includes(el)
                    ? "bold"
                    : "normal",
                  cursor: "pointer",
                }}
                className="text-bgColor paragraph m-0"
                onClick={() => {
                  if (ytQuoteData.sTags.includes(el)) {
                    dispatch(
                      setQuoteValues({
                        time: "sTags",
                        value: ytQuoteData.sTags.filter((t) => t !== el),
                      })
                    );
                  } else {
                    dispatch(
                      setQuoteValues({
                        time: "sTags",
                        value: [...ytQuoteData.sTags, el],
                      })
                    );
                  }
                }}
              >
                {i !== 0 ? ", " : ""}
                {el}
              </span>
            ))}
          </div>
        )}
        {/* <div className="tags">
          {TAGS.map((el, i) => (
            <span
              key={i}
              style={{
                fontWeight: ytQuoteData.tags.includes(el) ? "bold" : "normal",
                cursor: "pointer",
              }}
              className="text-bgColor paragraph m-0"
              onClick={() => {
                if (ytQuoteData.tags.includes(el)) {
                  dispatch(
                    setQuoteValues({
                      time: "tags",
                      value: ytQuoteData.tags.filter((t) => t !== el),
                    })
                  );
                } else {
                  dispatch(
                    setQuoteValues({
                      time: "tags",
                      value: [...ytQuoteData.tags, el],
                    })
                  );
                }
              }}
            >
              {i !== 0 ? ", " : ""}
              {el}
            </span>
          ))}
        </div> */}

        <section className="flex-wrap justify-content-center align-items-center gap-4 flex-wrap">
          <Button
            className="text-bgColor fw-bold text-lowercase"
            onClick={deleteQoute}
            variant="text"
          >
            Delete
          </Button>
          <Button
            className="text-bgColor fw-bold text-lowercase"
            onClick={startAgain}
            variant="text"
          >
            Play
          </Button>

          <Button
            className="text-bgColor fw-bold text-lowercase"
            onClick={handleSave}
            variant="text"
          >
            Save
          </Button>
          <Button
            className="text-bgColor fw-bold text-lowercase"
            onClick={() => {
              saveQoute();
              // dispatch(setEdit("tags"));
            }}
            variant="text"
          >
            Next
          </Button>
        </section>

        {/* </If> */}
      </div>
    </section>
  );
}
