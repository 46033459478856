import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";

export default function MorePage() {
  const moreData = [
    {
      title: "Do Not Blink",
      description:
        "Do Not Blink is a brain training game for the students. It helps you memorize long & difficult sentence by splitting it into smaller bits, which are eventually easier to memorize instead of a large sentence",
      url: "https://donotblink.app",
    },
    {
      title: "wrdz.app",
      description: "an app to learn words",
      url: "https://wrdz.app",
    },
  ];
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col sm={10} md={8} lg={6}>
          <section>
            <div className="text-center">
              <h1 className="main-heading mb-4">More Stuff</h1>
              <p className="paragraph mb-5">
                Here are other things you can try out. <br />
                Pretty sure you will like them, too.
              </p>
            </div>
            {moreData && (
              <Accordion defaultActiveKey={[]} alwaysOpen>
                {moreData.map((data, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{data.title}</Accordion.Header>
                    <Accordion.Body>
                      <p className="paragraph">{data.description}</p>
                      <a
                        href={data.url}
                        className="btn btn-dark rounded-pill px-5"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visit
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </section>
        </Col>
      </Row>
    </Container>
  );
}
