import React, {
  // useCallback,
  useState,
} from "react";
// import useLocalStorageState from "hooks/useLocalStorageState";
import { difficulties } from "pages/PrepareQuotes";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { db } from "config/firebase";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { useDetectAdBlock } from "adblock-detect-react";

export const initalLevels = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
};
export const CollectionNameSection = ({
  item,
  // showModal,
  // setShowPasswordModal,
  ...props
}) => {
  const adBlockDetected = useDetectAdBlock();

  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectedCollection] = useState(item.name.replace(/ /g, "-"));
  const [dataFatched, setDataFatched] = useState(false);
  const [levelsQoutes, setLevelsQoutes] = useState(initalLevels);
  //   const [selectedLevel, setSelectedLevel] = useLocalStorageState(
  //     "selectedLevel",
  //     [2, 3, 4]
  //   );

  // const [selectedCollection, setSelectedCollection] = useLocalStorageState(
  //   "selectedCollection",
  //   undefined
  // );

  const navigate = useNavigate();

  // const handleNavigation = useCallback(() => {
  //   if (showModal) setShowPasswordModal(true);
  //   else {
  //   navigate(`quotes/${selectedLevel}/${selectedCollection || "all"}/play/`);
  //   }
  // }, [
  //   navigate,
  //   selectedCollection,
  //   selectedLevel,
  //   setShowPasswordModal,
  //   showModal,
  // ]);

  // const handlePlay = useCallback(handleNavigation, [handleNavigation]);
  const handlePlay = () => {
    navigate(`quotes/${selectedLevel}/${selectedCollection || "all"}/play`);
  };

  const handleLevel = (item) => {
    // setSelectedLevel((prev) => {
    //   if (prev.includes(item)) {
    //     return prev.filter((i) => i !== item);
    //   } else {
    //     return [...prev, item];
    //   }
    // });
    setSelectedLevel([item]);
  };

  const getCollectionSet = async () => {
    if (dataFatched) return;
    setLevelsQoutes(initalLevels);
    setSelectedLevel([]);

    const qouteRef = collection(db, "quotes");
    const collectionQuery = query(
      qouteRef,
      where("collection", "array-contains", { name: item.name, id: item.id })
    );

    const totalQoute = await getCountFromServer(collectionQuery);
    console.log("totalQoute: ", totalQoute.data().count);

    const difficultyCounts = { ...initalLevels };
    const selectDefaultLevels = [];
    for (let difficulty = 1; difficulty <= 5; difficulty++) {
      const difficultyQuery = query(
        collectionQuery,
        where("difficulty", "==", difficulty)
      );
      const count = await getCountFromServer(difficultyQuery);
      difficultyCounts[difficulty] = count.data().count;
      if (difficulty > 1 && difficulty < 5) {
        if (count.data().count > 0) {
          selectDefaultLevels.push(difficulty);
        }
      }
    }

    console.log("difficultyCounts: ", difficultyCounts);
    console.log("selectDefaultLevels: ", selectDefaultLevels);

    setLevelsQoutes(difficultyCounts);
    if (selectDefaultLevels.length > 0) {
      const index = selectDefaultLevels.indexOf(3);
      setSelectedLevel(
        selectDefaultLevels.includes(3)
          ? selectDefaultLevels.slice(index, index + 1)
          : selectDefaultLevels.slice(0, 1)
      );
    }
    setDataFatched(true);
  };

  return (
    <Accordion.Item eventKey={item.id} onClick={getCollectionSet}>
      <Accordion.Header as={"div"} className="collection-accordion">
        <h3 className="text-uppercase m-0 heading-4 fw-bold text-wrap text-break w-100 text-center">
          {item.name}
        </h3>
      </Accordion.Header>
      <Accordion.Body className="text-center pt-0">
        {item?.description && (
          <p className="text-uppercase paragraph text-center col-lg-6 mx-auto">
            {item.description}
          </p>
        )}
        <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center mb-3">
          {difficulties.map((item, i) => (
            <button
              key={i}
              className={`circle-btn  border border-dark fw-bold ${
                selectedLevel.includes(item) ? "active" : ""
              }`}
              onClick={() => handleLevel(item)}
              disabled={levelsQoutes[item] === 0 || adBlockDetected}
              style={{
                opacity: levelsQoutes[item] === 0 ? 0.5 : 1,
              }}
            >
              {item}
            </button>
          ))}
        </div>

        <button
          className="btn btn-dark bg-black rounded-pill text-uppercase text-bgColor heading-4 px-5 py-1 paragraph"
          onClick={handlePlay}
          disabled={selectedLevel.length === 0 || adBlockDetected}
        >
          GO
        </button>
        {adBlockDetected && (
          <p className="text-center m-0 mt-2 paragraph">
            It <b>will not work</b> with ad block. Switch it off.
          </p>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};
