// // ** Redux Imports
import { useSelector } from "react-redux";
import reducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer,
  middleware: (middleware) =>
    middleware({
      serializableCheck: false,
    }),
});

/**
 * @typedef {ReturnType<typeof store.getState>} RootState
 * @typedef {ReturnType<typeof store.dispatch>} AppDispatch
 */

/**
 * @type {import('react-redux').TypedUseSelectorHook<RootState>}
 */
export const useAppSelector = useSelector;
