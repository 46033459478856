import { useState, useEffect } from "react";

/**
 * @template {any} T
 * @param {string} key
 * @param {T} defaultValue
 * @returns {[T, import("react").Dispatch<import("react").SetStateAction<T>>]}
 */

export default function useLocalStorageState(key, defaultValue) {
  const [state, setState] = useState(() => {
    let val;
    try {
      val = JSON.parse(
        window.localStorage.getItem(key) || String(defaultValue)
      );
    } catch (e) {
      val = defaultValue;
    }
    return val;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState];
}
