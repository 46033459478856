import React from "react";
import { Row } from "react-bootstrap";
export default function NotFound() {
  return (
    <Row className="d-flex justify-content-center align-items-center h-100 text-center">
      <h1 className="heading-1 text-capitalize">
        404
        <br />
        Page Not Found
      </h1>
    </Row>
  );
}
